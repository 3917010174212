<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="font-weight-medium">Journals</h3>
    <v-row class="mb-2">
      <v-col cols="12" md="4" sm="8">
        <div class="d-flex">
          <v-text-field
              placeholder="Search"
              v-model="searchValue"
              @keyup.enter="searchForItem(searchValue)"
          >
            <template v-slot:prepend-inner>
              <v-icon
                  icon="mdi-magnify"
                  variant="tonal"
                  color="button_color"
                  class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn
            color="button_color"
            @click="QTransactionModal = true"
            icon="mdi-help"
            class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="transactionsDetails">
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>

    <EasyDataTable
        :headers="headers"
        :items="transactionsDetails"
        v-model:serverOptions="serverOptions"
        :server-items-length="totalItems"
        :loading="loading"
        :search-field="searchField"
        :search-value="searchValue"
        :filter-options="filterOptions"
        body-text-direction="right"
        header-text-direction="right"
        class="custom-data-table"
        border-cell
        :body-row-class-name="bodyRowClassNameFunction"
        no-hover
    >
      <template #pagination="{}">
        <v-btn
            density="compact"
            class="ma-4"
            :disabled="serverOptions.page === 1"
            @click="goToPrevPage"
        >prev page
        </v-btn>
        <v-btn
            density="compact"
            class="ma-4"
            :disabled="serverOptions.page === numberOfPages"
            @click="goToNextPage"
        >next page
        </v-btn>
      </template>

      <template #header-transactionStatus="header">
        <div class="filter-column">
          <v-icon @click.stop="showStatusFilter = !showStatusFilter">
            mdi-filter
          </v-icon>
          {{ header.text }}
          <div class="filter-menu" v-if="showStatusFilter">
            <v-select
                class="filter-input"
                v-model="statusCriteria"
                name="status"
                :items="['ALL', 'INITIATED', 'COMPLETED']"
            >
            </v-select>
          </div>
        </div>
      </template>
      <template #header-transactionAmount>
        <div class="filter-column">
          <v-icon @click.stop="showAmountFilter = !showAmountFilter">
            mdi-filter
          </v-icon>
          {{ headers.find((h) => h.value === "transactionAmount").text }}
          <div class="filter-menu" v-if="showAmountFilter">
            <div class="amount-filter">
              <v-text-field
                  style="width: 80px; display: inline-block"
                  v-model.number="minAmount"
                  class="mr-2"
                  label="Min"
                  type="number"
                  step="0.01"
              ></v-text-field>

              <v-text-field
                  style="width: 80px; display: inline-block"
                  v-model.number="maxAmount"
                  label="Max"
                  type="number"
                  step="0.01"
              ></v-text-field>
            </div>
          </div>
        </div>
      </template>
    </EasyDataTable>
    <v-dialog v-model="QTransactionModal" width="auto">
      <Q_Transaction_Modal_V01></Q_Transaction_Modal_V01>
    </v-dialog>
  </v-container>
  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>
</template>

<script>
import Q_Transaction_Modal_V01 from "@/components/ModalQ/Q_Transaction_Modal_V01.vue";
import transactionService from "@/services/transaction.service";
import { PAGE_SIZE } from "@/config/constants";
import Broken_Page_V01 from "../Broken_Page_V01.vue";
import { getEventTypeDisplay } from "@/utils/NewFunctions/getEventTypeDisplay";
import { formatString } from "@/utils/NewFunctions/utils";
import {formatNumber, pageResponseHandler} from "@/utils/helpers";

export default {
  name: "list_of_transactions_v01",
  components: {
    Q_Transaction_Modal_V01,
    Broken_Page_V01,
  },
  data() {
    return {
      headers: [
        {text: "Status", value: "transactionStatus"},
        {text: "Account No", value: "accountNumber"},
        {text: "Third Party", value: "thirdPartyFullName"},
        {text: "Coffer", value: "cofferName"},
        {text: "Debit", value: "debitAmount"},
        {text: "Credit", value: "creditAmount"},
        {text: "Balance", value: "balance"},
        {text: "Ledger", value: "ledgerCode"},
        {text: "Type", value: "eventType"},
        {text: "Event", value: "eventId"},
        {text: "Created At", value: "createdAt"},
      ],
      searchField: "",
      searchValue: "",
      searchTargetOwner: "",
      searchCurrency: "",
      searchTargetAccount: "",
      searchRequestId: "",
      searchAccountingCode: "",
      message: null,
      broken_page: false,
      success: false,
      error: false,
      transactions: [],
      tableItems: [],
      currentPage: 0,
      pageSize: PAGE_SIZE,
      totalItems: 0,
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
      },
      statusCriteria: "ALL",
      showStatusFilter: false,
      showTargetThirdPartyFilter: false,
      showTransactionTargetAccount: false,
      showTransactionAccountingCode: false,
      showTransactionRequestId: false,
      showTransactionCurrency: false,
      showAmountFilter: false,
      QTransactionModal: false,
      minAmount: null,
      maxAmount: null,
      loading: false,
    };
  },

  methods: {
    bodyRowClassNameFunction(item) {
      switch (item.transactionStatus) {
        case "Canceled":
          return "row-canceled";
        default:
          return "";
      }
    },
    goToPrevPage() {
      this.serverOptions.page = this.serverOptions.page - 1;
      this.getTransactions();
    },
    goToNextPage() {
      this.serverOptions.page = this.serverOptions.page + 1;
      this.getTransactions();
    },
    isOnlySpaces(str) {
      return str.trim().length === 0;
    },

    async searchForItem(searchValue) {
      if (this.isOnlySpaces(searchValue)) {
        this.searchValue = '';
        return;
      }
      searchValue = searchValue.replace(/^\s+|\s+$/g, '');
      const {page, rowsPerPage} = this.serverOptions
      const isNumber = /^\d+(\.\d+)?$/.test(searchValue);
      const isString = /^[a-zA-Z ]+$/.test(searchValue);
      let numericalSearchField = isNumber ? searchValue : null;
      let searchField = isString ? searchValue : null;
      this.loading = true;
      let response = pageResponseHandler(
          await transactionService.getTransactions(page - 1, rowsPerPage, searchField, numericalSearchField)
      );
      this.loading = false;
      if (response.success) {
        this.totalItems = response.totalItems;
        this.numberOfPages = response.numberOfPages;
        this.transactions = response.data;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    async getTransactions() {
      let response;
      this.loading = true;
      response = await transactionService.getTransactions(
          this.serverOptions.page - 1,
          this.serverOptions.rowsPerPage,
      );
      console.log("response", response);
      if (response.status === 200) {
        this.loading = false;
        this.transactions = response.data.data;
        console.log("transactions", this.transactions);
        this.totalItems = response.data.totalElements;
        this.numberOfPages = response.numberOfPages;
        console.log("this.numberOfPages", this.numberOfPages);
      } else {
        this.broken_page = true;
      }
    },
  },

  async mounted() {
    await this.getTransactions();
  },
  computed: {
    filterOptions() {
      const filterOptionsArray = [];
      if (this.statusCriteria !== "ALL") {
        filterOptionsArray.push({
          field: "transactionStatus",
          comparison: "=",
          criteria: this.statusCriteria,
        });
      }
      if (this.searchTargetOwner !== "") {
        filterOptionsArray.push({
          field: "transactionTargetThirdParty",
          comparison: "includes",
          criteria: this.searchTargetOwner,
        });
      }

      if (this.searchCurrency !== "") {
        filterOptionsArray.push({
          field: "transactionCurrency",
          comparison: "includes",
          criteria: this.searchCurrency,
        });
      }
      if (this.searchTargetAccount !== "") {
        filterOptionsArray.push({
          field: "transactionTargetAccount",
          comparison: "includes",
          criteria: this.searchTargetAccount,
        });
      }
      if (this.searchRequestId !== "") {
        filterOptionsArray.push({
          field: "transactionRequestId",
          comparison: "==",
          criteria: Number(this.searchRequestId),
        });
      }
      if (this.searchAccountingCode !== "") {
        filterOptionsArray.push({
          field: "transactionAccountingCode",
          comparison: "include",
          criteria: this.searchAccountingCode,
        });
      }

      if (
          (this.minAmount !== null && this.minAmount !== "") ||
          (this.maxAmount !== null && this.maxAmount !== "")
      ) {
        filterOptionsArray.push({
          field: "transactionAmount",
          comparison: "between",
          criteria: [this.minAmount, this.maxAmount],
        });
      }
      return filterOptionsArray;
    },

    transactionsDetails() {
      return this.transactions.map((transaction) => {
        let transactionTypeDisplay = getEventTypeDisplay(transaction);
        return {
          id: transaction.id,
          transactionStatus: formatString(transaction.transactionStatus),
          accountId: transaction.account.id,
          accountNumber: transaction.thirdParty.accountNumber,
          thirdPartyFullName: transaction.thirdParty.fullName,
          cofferName: transaction.account.cofferName,
          ledgerCode: transaction.ledgerCode,
          creditAmount:
              transaction.ledgerDirection === "CREDIT"
                  ? transaction.currency + " " + formatNumber(transaction.amount)
                  : "",
          debitAmount:
              transaction.ledgerDirection === "DEBIT"
                  ? transaction.currency + " " + formatNumber(transaction.amount)
                  : "",
          amount: transaction.currency + " " + formatNumber(transaction.amount),
          direction: transaction.ledgerDirection,
          balance:
              transaction.currency +
              " " +
              formatNumber(transaction.ledgerBalance),
          eventId: transaction.eventId,
          createdAt: new Date(transaction.createdAt)
              .toISOString()
              .split("T")[0],
          transactionType: transaction.transactionType,
          eventType: transactionTypeDisplay,
        };
      });
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.serverOptions.rowsPerPage);
    },
  },
  watch: {
    searchValue(newVal) {
      if (newVal === "") {
        clearTimeout(this.debounceTimer);
        this.serverOptions.page = 1;
        this.getTransactions();
      } else {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.searchForItem(newVal);
        }, 500);
      }
    },
    serverOptions: {
      handler() {
        if (this.searchValue !== null && this.searchValue !== "")
          this.searchForItem(this.searchValue)
        else
          this.getTransactions();
      },
      deep: true,
    },
  },
};
</script>
<style>
.filter-input {
  width: 120px;
}
</style>
