<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium mb-2">{{ thirdPartyTypeFormat }} Accounts</h3>
      <v-row>
        <v-col cols="12" md="4" sm="8">
          <div class="d-flex">
            <v-text-field
                placeholder="Search"
                v-model="searchValue"
            >
              <template v-slot:prepend-inner>
                <v-icon
                    icon="mdi-magnify"
                    variant="tonal"
                    color="button_color"
                    class="mr-3"
                >
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>


        <v-col class="d-flex justify-end">
          <v-btn
              v-if="thirdPartyType === 'TRUSTEE'"
              color="button_color"
              @click="QTrusteeAccountsModal = true"
              icon="mdi-help"
              class="ml-2"
          >
          </v-btn>
          <v-btn
              v-if="thirdPartyType === 'CUSTOMER'"
              color="button_color"
              @click="QCustomerAccountsModal = true"
              icon="mdi-help"
              class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="thirdPartyAccounts"
            >
              <v-icon>mdi-export</v-icon>
            </download-excel
            >
          </v-btn>
        </v-col>
      </v-row>
      <EasyDataTable
          :headers="headers"
          :items="accountDetails"
          :search-field="searchField"
          :search-value="searchValue"
          body-text-direction="center"
          header-text-direction="center"
          alternating
          border-cell
          class="custom-data-table"
          :loading="loading"
      >
        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>

        <template #item-userDetails="item">
          <v-icon @click="showThirdPartyDetails(item)">mdi-chevron-down</v-icon>
        </template>
        <template #item-balance="{ balance }">
          <div class="currency-amount">
            <span class="currency">{{
                splitCurrencyValue(balance).currency
              }}</span>
            <span class="text-right">{{
                splitCurrencyValue(balance).value
              }}</span>
          </div>
        </template>
        <template #item-inTransitCreditAmount="{ inTransitCreditAmount }">
          <div class="currency-amount">
            <span class="currency">{{
                splitCurrencyValue(inTransitCreditAmount).currency
              }}</span>
            <span class="text-right">{{
                splitCurrencyValue(inTransitCreditAmount).value
              }}</span>
          </div>
        </template>
        <template #item-inTransitDebitAmount="{ inTransitDebitAmount }">
          <div class="currency-amount">
            <span class="currency">{{
                splitCurrencyValue(inTransitDebitAmount).currency
              }}</span>
            <span class="text-right">{{
                splitCurrencyValue(inTransitDebitAmount).value
              }}</span>
          </div>
        </template>
        <template #item-blockedAmount="{ blockedAmount }">
          <div class="currency-amount">
            <span class="currency">{{
                splitCurrencyValue(blockedAmount).currency
              }}</span>
            <span class="text-right">{{
                splitCurrencyValue(blockedAmount).value
              }}</span>
          </div>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="QTrusteeAccountsModal" width="auto">
      <Q_TrusteeAccounts_Modal_V01></Q_TrusteeAccounts_Modal_V01>
    </v-dialog>
    <v-dialog v-model="QCustomerAccountsModal" width="auto">
      <Q_CustomerAccounts_Modal_V01></Q_CustomerAccounts_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import accountService from "@/services/account.service";
import {setPage, unifiedResponseHandler, formatNumber} from "@/utils/helpers";
import Broken_Page_V01 from "../Broken_Page_V01.vue";
import Q_TrusteeAccounts_Modal_V01 from "@/components/ModalQ/Q_TrusteeAccounts_Modal_V01.vue";
import Q_CustomerAccounts_Modal_V01 from "@/components/ModalQ/Q_CustomerAccounts_Modal_V01.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";

export default {
  name: "list_of_accounts_v01",
  props: ["thirdPartyType"],
  components: {
    Error_Modal,
    Success_Modal,
    Broken_Page_V01,
    Q_TrusteeAccounts_Modal_V01,
    Q_CustomerAccounts_Modal_V01,
  },
  data() {
    return {
      trusteeHeaders: [
        {text: "Coffer Name", value: "cofferName"},
        {text: "Third Party", value: "thirdParty"},
        {text: "Balance", value: "balance"},
        {text: "In Transit Credit", value: "inTransitCreditAmount"},
        {text: "In Transit Debit", value: "inTransitDebitAmount"},
        {text: "Bank Name", value: "bankName"},
        {text: "Account Number", value: "accountNumber"},
        {text: "IBAN", value: "ibannumber"},
      ],
      customerHeaders: [
        {text: "Coffer Name", value: "cofferName"},
        {text: "Third Party", value: "thirdParty"},
        {text: "Balance", value: "balance"},
        {text: "In Transit Credit", value: "inTransitCreditAmount"},
        {text: "In Transit Debit", value: "inTransitDebitAmount"},
        {text: "Blocked Amount", value: "blockedAmount"},
      ],
      safeBoxHeaders: [
        {text: "Coffer Name", value: "cofferName"},
        {text: "Third Party", value: "thirdParty"},
        {text: "Balance", value: "balance"},
        {text: "In Transit Credit", value: "inTransitCreditAmount"},
        {text: "In Transit Debit", value: "inTransitDebitAmount"},
        {text: "Blocked Amount", value: "blockedAmount"},
      ],
      payerPayeeHeaders: [
        {text: "Coffer Name", value: "cofferName"},
        {text: "Third Party", value: "thirdParty"},
        {text: "Balance", value: "balance"},
        {text: "In Transit Credit", value: "inTransitCreditAmount"},
        {text: "In Transit Debit", value: "inTransitDebitAmount"},
        {text: "Bank Name", value: "bankName"},
        {text: "Account Number", value: "accountNumber"},
        {text: "IBAN", value: "ibannumber"},
      ],
      searchField: "",
      searchValue: "",
      message: null,
      broken_page: false,
      success: false,
      error: false,
      QTrusteeAccountsModal: false,
      QCustomerAccountsModal: false,
      thirdPartyAccounts: [],
      loading: false,
    };
  },

  methods: {
    splitCurrencyValue(currencyString) {
      const parts = currencyString.split(" ");
      return {
        currency: parts[0],
        value: parts[1],
      };
    },
    goToEdit(third_party) {
      this.$store.dispatch("setChosenThirdParty", third_party);
      this.$store.dispatch("setIsEditingThirdParty", true);
      setPage("new_third_party");
    },

    async getAccounts() {
      let response;
      this.loading = true;

      if (this.thirdPartyType === "TRUSTEE") {
        console.log("thirdPartyType", this.thirdPartyType);
        response = unifiedResponseHandler(
            await accountService.getAllThirdPartiesAccountByThirdPartyType('TRUSTEE')
        );
      } else if (this.thirdPartyType === "CUSTOMER") {
        response = unifiedResponseHandler(
            await accountService.getAllThirdPartiesAccountByThirdPartyType('CUSTOMER')
        );
      } else if (this.thirdPartyType === "SAFE_BOX") {
        response = unifiedResponseHandler(
            await accountService.getAllThirdPartiesAccountByThirdPartyType('SAFE_BOX')
        );
      } else if (this.thirdPartyType === "SECONDARY_THIRDPARTY") {
        response = unifiedResponseHandler(
            await accountService.getAllThirdPartiesAccountByThirdPartyType('SECONDARY_THIRDPARTY')
        );
      } else {
        console.log(this.thirdPartyType);
      }
      console.log("response", response);
      if (response.success) {
        this.thirdPartyAccounts = response.data;
        this.loading = false;
        console.log("thirdPartyAccounts", response);
      } else {
        this.broken_page = true;
      }
    },
  },

  async mounted() {
    await this.getAccounts();
  },
  computed: {
    headers() {
      const { thirdPartyType } = this;
      const headersMap = {
        TRUSTEE: this.trusteeHeaders,
        CUSTOMER: this.customerHeaders,
        SAFE_BOX: this.safeBoxHeaders,
        default: this.payerPayeeHeaders
      };
      return headersMap[thirdPartyType] || headersMap.default;
    },
    thirdPartyTypeFormat() {
      switch (this.thirdPartyType) {
        case "TRUSTEE":
          return "Trustee";
        case "CUSTOMER":
          return "Customer";
        case "SAFE_BOX":
          return "Safebox";
        case "SECONDARY_THIRDPARTY":
          return "Payer/Payee";
        default:
          return ""
      }
    },
    accountDetails() {
      return this.thirdPartyAccounts.map((account) => ({
        balance: account.currency.symbol + " " + formatNumber(account.actualAmount),
        inTransitCreditAmount: account.currency.symbol + " " + formatNumber(account.inTransitCreditAmount),
        inTransitDebitAmount: account.currency.symbol + " " + formatNumber(account.inTransitDebitAmount),
        accountGroup: account.accountType.accountGroup,
        accountLedgerCode: account.accountType.generalLedger.code,
        bankName: account.thirdPartyBankInfo ? account.thirdPartyBankInfo.bankName : " ",
        accountNumber: account.thirdPartyBankInfo ? account.thirdPartyBankInfo.accountNumber : " ",
        ibannumber: account.thirdPartyBankInfo ? account.thirdPartyBankInfo.ibannumber : " ",
        id: account.id,
        blockedAmount: account.currency.symbol + " " + formatNumber(account.blockedAmount),
        thirdParty: account.thirdParty.fullName,
        cofferName: account?.cofferName || null,
      }));
    },
  },
};
</script>
