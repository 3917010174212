<template>
  <v-container fluid>
    <v-form ref="new_third_party_group_form" class="mt-4">
      <v-row>
        <v-col md="4">
          <v-text-field v-model="thirdPartyGroup.name" clearable label="Group Name"
                        :rules="requiredRule()"></v-text-field>
        </v-col>

        <v-col md="8">
          <v-text-field v-model="thirdPartyGroup.description" clearable label="Description"></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn class="mr-4" color="button_color"
                 @click="addOrUpdateThirdPartyGroup">
            {{ this.buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>

  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>

</template>


<script>
import {unifiedResponseHandler} from "@/utils/helpers.js";
import third_party_groupService from "@/services/third_party_group.service.js";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import {mapGetters} from "vuex";
import {requiredRule} from "@/utils/rules.js";

export default {
  components: {Success_Modal, Error_Modal},
  props: ['thirdPartyGroupId'],

  data() {
    return {
      message: "",
      chosenUser: null,
      success: false,
      error: false,
      enteredUserId: "",
      userListModal: false,
      thirdPartyGroup: {
        id: null,
        name: null,
        description: null
      },
    }
  },
  methods: {
    requiredRule() {
      return requiredRule
    },
    addOrUpdateThirdPartyGroup() {
      if (this.is_editing_third_party_group) {
        this.editThirdPartyGroup()
      } else {
        this.addThirdPartyGroup()
      }
    },
    async editThirdPartyGroup() {
      if (await this.$refs.new_third_party_group_form.validate()) {
        this.loading = true;
        let response = unifiedResponseHandler(await third_party_groupService.editThirdPartyGroup(this.thirdPartyGroup));
        this.message = response.message;

        if (response.success) {
          this.loading = false;
          this.success = true;
        } else {
          this.loading = false; // Ensure loading is stopped on error as well
          this.error = true;
        }
      }
    },
    async addThirdPartyGroup() {
      if (await this.$refs.new_third_party_group_form.validate()) {
        if (this.thirdPartyGroup.name !== null)
          this.thirdPartyGroup.name = this.thirdPartyGroup.name.trim()
        let form = this.thirdPartyGroup;
        let response = unifiedResponseHandler(await third_party_groupService.addThirdPartyGroup(form));
        this.message = response.message
        if (response.success) {
          this.$store.dispatch("setChosenThirdPartyGroup", response.data)
          this.$store.dispatch("setIsEditingThirdPartyGroup", true)
          this.success = true
        } else {
          this.error = true
        }
      }
    },
  },
  computed: {
    ...mapGetters(["chosen_third_party_group", "is_editing_third_party_group"]),
    buttonText() {
      return this.is_editing_third_party_group ? "Update Third Party Group" : "Create Third Party Group";
    }
  },
  async mounted() {
    if (this.is_editing_third_party_group) {
      this.thirdPartyGroup = this.chosen_third_party_group
    }
  }
}
</script>


<style scoped>
h3 {
  color: #000000;
  font-family: sans-serif;
  padding: 10px 10px 10px 50px;
}
</style>