<template>
  <v-sheet max-width="800" min-width="600">
    <div style="padding: 20px">
      <div class="border-1">
        <h4 class="border-h4">Crypto Deal Summary</h4>
        <div
          class="grid-card-2"
          ref="pdfContent"
          style="text-align: left; direction: ltr"
        >
          <p>Event:</p>
          <p class="m-l-35">{{ chosen_crypto_deal.id }}</p>
          <p>Status:</p>
          <p class="m-l-35">
            {{ getStatusDisplay(chosen_crypto_deal.status) }}
          </p>
          <p>Reference No:</p>
          <p class="m-l-35">{{ chosen_crypto_deal.referenceNo }}</p>
          <p>Create Date:</p>
          <p class="m-l-35">{{ chosen_crypto_deal.createDate }}</p>
          <p>Third Party:</p>
          <p class="m-l-35">
            {{ chosen_crypto_deal.thirdParty?.fullName || " " }}
          </p>
          <p>Exchange Rate:</p>
          <p class="m-l-35">{{ chosen_crypto_deal.exchangeRate }}</p>
          <p>Bought Amount:</p>
          <p class="m-l-35">
            {{
              formatNumber(chosen_crypto_deal.receiveAmount) +
              " " +
              chosen_crypto_deal.receiveCurrency.symbol
            }}
          </p>
          <p>Sold Amount:</p>
          <p class="m-l-35">
            {{
              formatNumber(chosen_crypto_deal.sendAmount) +
              " " +
              chosen_crypto_deal.sendCurrency.symbol
            }}
          </p>
          <p>Receive Our Acc:</p>
          <p class="m-l-35">
            {{ chosen_crypto_deal.ellingtonReceiveAccount?.cofferName || "" }}
          </p>

          <p>Receive Third Party Acc:</p>
          <p class="m-l-35">
            {{ chosen_crypto_deal.counterPartyPayerAccount?.cofferName || "" }}
          </p>

          <p>Send Our Acc:</p>
          <p class="m-l-35">
            {{ chosen_crypto_deal.ellingtonSendAccount?.cofferName || "" }}
          </p>
          <p>Send Third Party Acc:</p>
          <p class="m-l-35">
            {{ chosen_crypto_deal.counterPartyPayeeAccount?.cofferName || "" }}
          </p>
        </div>
        <v-row justify="end">
          <v-btn class="mr-4" color="button_color" @click="generatePDF"
            >PDF
          </v-btn>
        </v-row>
      </div>
    </div>
  </v-sheet>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import getStatusDisplay from "@/utils/NewFunctions/getStatusDisplay.js";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import { formatNumber } from "@/utils/helpers.js";

export default {
  name: "request_modal_v01",
  components: {
    Error_Modal,
  },
  data() {
    return {
      request: null,
      currency: null,
      customer: null,
      amount: null,
      createDate: null,
      status: null,
      message: null,
      error: false,
    };
  },
  methods: {
    formatNumber,
    getStatusDisplay,
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: "cryptoSummary.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      html2pdf(element, options);
    },
  },
  computed: {
    ...mapGetters(["chosen_crypto_deal"]),
  },
};
</script>
