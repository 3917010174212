<template>
  <v-container
    fluid
    style="
      background-color: white;
      min-height: 300px;
      width: 900px;
      border-radius: 5px;
    "
  >
    <v-form ref="edit_reminder_form" class="mt-4">
      <v-row>
        <v-col v-if="isEditingReminder" md="2">
          <v-text-field v-model="id" label="ID" readonly variant="outlined">
          </v-text-field>
        </v-col>

        <v-col :md="isEditingReminder ? '4' : '6'">
          <v-text-field
            v-model="title"
            :rules="requiredRule()"
            label="Title"
            color="primaryColor1"
          >
          </v-text-field>
        </v-col>
        <v-col :md="isEditingReminder ? '3' : '3'" class="pt-2">
          <VueDatePicker
              placeholder="Date"
              :disabled="reminderType === 'CONTINUOUS'"
              v-model="alarmDate"
              format="yyyy-MM-dd"
              :auto-apply="true"
              :enable-time-picker="false"
          ></VueDatePicker>
        </v-col>
        <v-col :md="isEditingReminder ? '3' : '3'" class="pt-2">
          <VueDatePicker
              placeholder="Alarm Time"
              :disabled="reminderType==='CONTINUOUS'"
              v-model="alarmTime"
              format="HH:mm"
              :time-picker="true"
          >
          </VueDatePicker>
        </v-col>

      </v-row>
      <v-row>
        <v-col md='3'>
          <v-select
              v-model="selectedEarlyReminder"
              :items="this.earlyReminderOptions"
              item-title="text"
              item-value="value"
              label="Early Reminder"
              :disabled="reminderType === 'CONTINUOUS'"
              clearable
              prepend-inner-icon="mdi-clock"
              color="primaryColor1">
          </v-select>
        </v-col>
        <v-col md='3'>
          <v-select
              v-model="selectedRepeatInterval"
              :items="this.repeatIntervalOptions"
              item-title="text"
              item-value="value"
              label="Repeat Interval"
              :disabled="reminderType === 'SCHEDULED'"
              clearable
              prepend-inner-icon="mdi-repeat"
              color="primaryColor1">
          </v-select>
        </v-col>
        <v-col md="6">
          <v-text-field
              v-model="note"
              clearable
              label="Note"
              color="primaryColor1"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row justify="end">
        <v-col cols="auto">
          <v-btn
            :variant="isEditingReminder ? 'outlined' : 'flat'"
            :rules="requiredRule()"
            class="mr-4"
            color="button_color"
            @click="addOrUpdateReminder"
          >
            {{ buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>

    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
  </v-container>
</template>

<script>
import {unifiedResponseHandler} from "@/utils/helpers.js";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import {requiredRule} from "@/utils/rules.js";
import emitter from "@/plugins/mitt.js";
import reminderService from "@/services/reminder.service.js";

export default {
  name: "createReminderModal",
  components: { Success_Modal, Error_Modal },
  props: ["reminderToEdit"],

  data() {
    return {
      message: "",
      reminder: null,
      isEditingReminder: false,
      success: false,
      error: false,
      id: null,
      title: null,
      note: null,
      alarmDate: null,
      alarmTime: null,
      reminderType : "UNSELECTED",
      isActive: true,
      owner: null,
      reminderTypes: [
        { text: "Scheduled", value: "SCHEDULED" },
        { text: "Continuous", value: "CONTINUOUS" }
      ],
      repeatIntervalOptions: [
        { text: "1 Day", value: "1440" },
        { text: "2 Day", value: "2880" },
        { text: "1 week", value: "10080" },
        { text: "1 Month", value: "43200" },
        { text: "6 Month", value: "259200" },
        { text: "1 Year", value: "518400" },
      ],
      earlyReminderOptions: [
        { text: "30 Min", value: "30" },
        { text: "1 Hour", value: "60" },
        { text: "2 Hour", value: "120" },
        { text: "4 Hour", value: "240" },
        { text: "12 Hour", value: "720" },
        { text: "1 Day", value: "1440" },
        { text: "2 Day", value: "2880" },
        { text: "1 week", value: "10080" },
        { text: "1 Month", value: "43200" },
      ],
      selectedEarlyReminder : null,
      selectedRepeatInterval : null,
    };
  },

  computed: {
    buttonText() {
      return this.isEditingReminder ? "Update Reminder" : "Create Reminder";
    },
  },

  methods: {
    requiredRule() {
      return requiredRule;
    },

    addOrUpdateReminder() {
      if (!this.title
          || ((this.reminderType === "SCHEDULED" || this.reminderType === "UNSELECTED")
              && !this.alarmDate && !this.alarmTime)
          || (this.reminderType === "CONTINUOUS" && !this.selectedRepeatInterval)) {
        this.message = "Please fill in all required fields";
        this.error = true;
        return;
      }
      if (this.isEditingReminder) {
        this.editReminder();
      } else {
        this.addReminder();
      }
    },

    createForm(){
      return {
        id: this.id,
        title: this.title,
        note: this.note,
        isActive: this.isActive,
        type: this.reminderType === "CONTINUOUS" ? "CONTINUOUS" : "SCHEDULED",
        repeatInterval: this.selectedRepeatInterval,
        earlyReminder: this.selectedEarlyReminder,
        alarmDate: this.reminderType !== "CONTINUOUS" ? this.formatDateTime(this.alarmDate, this.alarmTime) : null,
      };
    },

    async editReminder() {
      if (await this.$refs.edit_reminder_form.validate()) {
        let response = unifiedResponseHandler(
          await reminderService.editReminder( this.createForm()),
        );
        if (response.success) {
          this.message = "Reminder has been edited successfully";
          this.success = true;
          emitter.emit("successful-reminder-operation");
        } else {
          this.message = response.message;
          this.error = true;
        }
      }
    },

    async addReminder() {
      if (await this.$refs.edit_reminder_form.validate()) {
        let response = unifiedResponseHandler(
          await reminderService.addReminder( this.createForm()),
        );
        if (response.success) {
          this.message = "Reminder has been added successfully";
          this.success = true;
          this.setReminderToEditVariables(response.data);
          this.isEditingReminder = true;
          emitter.emit("successful-reminder-operation");
        } else {
          this.message = response.message;
          this.error = true;
        }
      }
    },

    setReminderToEditVariables(newReminder) {
      if (newReminder) {
        this.isEditingReminder = true;
        this.reminder = newReminder;
        this.id = this.reminder.id;
        this.title = this.reminder.title;
        this.isActive = this.reminder.isActive;
        this.owner = this.reminder.owner;
        this.note = this.reminder.note;
        this.reminderType = this.reminder.type
        if(this.reminderType === "SCHEDULED"){
          this.alarmDate = this.reminder.alarmDate.slice(0,10);
          this.alarmTime = this.extractTime(this.reminder.alarmDate)
          this.selectedEarlyReminder = this.reminder.earlyReminder
        } else {
          this.selectedRepeatInterval = this.reminder.repeatInterval
          this.alarmDate = null;
          this.alarmTime = null;
        }
      }
    },

    formatDateString(isoString) {
      const date = new Date(isoString);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    },

    formatTimeWithZeroPadding({ hours, minutes }) {
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    },

    formatDateTime(date, time) {
      return `${this.formatDateString(date)} ${this.formatTimeWithZeroPadding(time)}`;
    },

    extractTime(dateTime) {
      return {
        hours: dateTime.slice(11, 13),
        minutes: dateTime.slice(14, 16),
        seconds: "00",
      };
    },
  },
  async mounted() {
    this.setReminderToEditVariables(this.reminderToEdit);
  },
  watch: {
    selectedEarlyReminder(newVal , oldVal){
      if(oldVal == null) {
        this.reminderType = "SCHEDULED"
      } else if(newVal == null) {
        this.reminderType = "UNSELECTED"
      }
    },
    selectedRepeatInterval(newVal , oldVal){
      if(oldVal == null) {
        this.reminderType = "CONTINUOUS"
        this.alarmTime = null
        this.alarmDate = null;
      } else if(newVal == null) {
        this.reminderType = "UNSELECTED"
      }
    },
  },
};
</script>

<style scoped></style>
