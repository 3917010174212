import axios from "axios";
import {BASE_URL} from "../config/endpoints.js";

class AuthService {

    async signIn(signInForm) {
        try {
            return await axios.post(BASE_URL + "auth/signin", signInForm);
        } catch (error) {
            return {status: 500, data: null, message: error.response.data}
        }
    }
    async verifyOTP(otpVerificationForm) {
      const response = await axios.post(BASE_URL + "auth/verifyotp", otpVerificationForm);
      if (response.status === 200 || response.data.code === 200) {
        localStorage.setItem("auth/user", JSON.stringify(response.data));
        return response.data.data;
      } else {
        throw new Error("Incorrect OTP, please try again");
      }
    }

    logout() {
        localStorage.removeItem("auth/user");
    }
}

export default new AuthService();
