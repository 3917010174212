import axios from "axios";
import { authHeader } from "./auth-header";
import { BASE_URL } from "@/config/endpoints";

class meetingService {
    async addMeeting(meeting) {
        try {
            return await axios.post(
                BASE_URL + "meeting",
                meeting,
                {
                    headers: authHeader(),
                }
            );
        } catch (error) {
            console.log(error);
            return { status: 500, data: null, message: error.response.data };
        }
    }

    async editMeeting(meeting) {
        try {
            return await axios.post(
                BASE_URL + "meeting/edit",
                meeting,
                {
                    headers: authHeader(),
                }
            );
        } catch (error) {
            return { status: 500, data: null, message: error.response.data };
        }
    }
    async deleteMeeting(meetingId) {
        try {
            return await axios.delete(BASE_URL + "meeting?id=" + meetingId, {
                headers: authHeader(),
            });
        } catch (error) {
            return { status: 500, data: null, message: error.response.data };
        }
    }

    async getAllMeetings(){
        try {
            return await axios.get(BASE_URL + "meeting/getAll", {
                headers: authHeader(),
            });
        } catch (error) {
            return { status: 500, data: null, message: error.response.data };
        }
    }

    async getMeetingById(meetingId) {
        try {
            return await axios.get(BASE_URL + 'meeting?id=' + meetingId, {
                headers: authHeader(),
            });
        } catch (error) {
            return { status: 500, data: null, message: error.response.data };
        }
    }
}

export default new meetingService();
