<script>

import Third_party_list_modal from "@/components/Modals/Third_Party_List_Modal_V01.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import {requiredRule} from "@/utils/rules.js";
import third_party_groupService from "@/services/third_party_group.service.js";
import emitter from "@/plugins/mitt.js";
import {handleKeyDown, unifiedResponseHandler} from "@/utils/helpers.js";

export default {
  name: "addThirdPartyToGroupModal",
  props: ['thirdPartyGroup'],
  components: {Third_party_list_modal, Success_Modal, Error_Modal},

  data() {
    return {
      enteredId: "",
      message: "",
      addThirdPartyModal: false,
      success: false,
      error: false,
      loading: true,
      enteredThirdPartyId: "",
      thirdPartyListModal: false,
      chosenThirdParty: null
    }
  },
  methods: {
    handleKeyDown,
    requiredRule() {
      return requiredRule
    },
    async addThirdPartyToGroup() {
      if (this.enteredThirdPartyId === "" || this.enteredThirdPartyId === null) {
        this.message = "Please enter third party id";
        this.error = true;
        return;
      }
      if (await this.$refs.new_third_party_group_form.validate()) {
        let response = unifiedResponseHandler(await third_party_groupService.addThirdPartyToGroup(this.thirdPartyGroup.id, this.enteredThirdPartyId))
        this.message = response.message
        if (response.success) {
          this.loading = false;
          this.success = true;
          emitter.emit("successThirdPartyOperation", response.data);
        } else {
          this.error = true;
        }
        this.chosenThirdParty = null;
        this.enteredThirdPartyId = "";
      }
    },
  },
  computed: {
    chosenThirdPartyFullName() {
      return this.chosenThirdParty?.fullName || null;
    },
  },
  async mounted() {
    emitter.on("new_third_party", (value) => {
      if (this.thirdPartyListModal) {
        this.chosenThirdParty = value;
        this.enteredThirdPartyId = this.chosenThirdParty.id
        this.thirdPartyListModal = false;
      }
    });
    await this.getThirdPartiesInGroup();
  }
}
</script>

<template>
  <v-container fluid style="background-color: white; width: 450px; height: 250px; border-radius: 5px;">
  <v-form ref="new_third_party_group_form" class="mt-4" >
      <v-row>
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-tab
              @click="thirdPartyListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
          >select
          </v-tab>
          <v-text-field
              v-model="chosenThirdPartyFullName"
              label="Selected Third Party Name"
              @keydown="handleKeyDown"
              :rules="requiredRule()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="mt-0 mb-0" cols="auto">
          <v-btn class="mr-4" color="button_color"
                 @click="addThirdPartyToGroup"
          >Add Third Party To Group
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>


  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>

  <v-dialog v-model="thirdPartyListModal" width="auto">
    <Third_party_list_modal
        thirdPartyType="ALL"
    ></Third_party_list_modal>
  </v-dialog>

</template>

<style scoped>

</style>