<template>
  <v-card>
    <v-form ref="edit_meeting_form">
      <v-card-text>
        <v-row dense>
          <v-col v-if="isEditingMeeting" md="2">
            <v-text-field v-model="id" label="ID" readonly variant="outlined">
            </v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field
                v-model="title"
                :rules="requiredRule()"
                label="Title"
                color="primaryColor1"
            >
            </v-text-field>
          </v-col>
          <v-col md="4">
            <v-select
                v-model="selectedImportanceLevel"
                :items="importanceLevels"
                :rules="requiredRule()"
                label="Importance"
                color="primaryColor1"
            >
            </v-select>
          </v-col>
          <v-col md="4">
            <v-select
                v-model="hasReminder"
                :items="hasReminderOptions"
                item-title="text"
                item-value="value"
                label="Has Reminder"
                color="primaryColor1"
            >
            </v-select>
          </v-col>
          <v-col md="12">
            <v-text-field
                v-model="note"
                clearable
                label="Note"
                color="primaryColor1"
            >
            </v-text-field>
          </v-col>
          <v-col md="12">
            <v-autocomplete
                v-model="selectedUsers"
                :items="this.users"
                item-title="firstName"
                item-value="id"
                label="Attendees"
                color="primaryColor1"
                multiple
                chips
                closable-chips
                :rules="requiredRule()"
            >
            </v-autocomplete>
          </v-col>
          <v-col md="4">
            <VueDatePicker
                placeholder="Select Date"
                v-model="meetingDate"
                format="yyyy-MM-dd"
                auto-apply
                :enable-time-picker="false"
                teleport-center
            ></VueDatePicker>
          </v-col>
          <v-col md="4">
            <VueDatePicker
                placeholder="Start Time"
                v-model="startTime"
                format="HH:mm"
                auto-apply
                time-picker
                teleport-center
            ></VueDatePicker>
          </v-col>
          <v-col md="4">
            <VueDatePicker
                placeholder="End Time"
                v-model="endTime"
                format="HH:mm"
                :rules="requiredRule()"
                time-picker
                auto-apply
                teleport-center
            ></VueDatePicker>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :variant="isEditingMeeting ? 'outlined' : 'flat'"
        :rules="requiredRule()"
        class="mr-4"
        color="button_color"
        @click="addOrUpdateMeeting"
      >
        {{ buttonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { unifiedResponseHandler } from "@/utils/helpers.js";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import { requiredRule } from "@/utils/rules.js";
import meetingService from "@/services/meeting.service.js";
import emitter from "@/plugins/mitt.js";
import userService from "@/services/user.service.js";

export default {
  name: "createMeetingModal",
  components: { Success_Modal, Error_Modal },
  props: ["meetingToEdit"],

  data() {
    return {
      message: "",
      id: null,
      meeting: null,
      isEditingMeeting: false,
      success: false,
      error: false,
      title: null,
      note: null,
      selectedImportanceLevel: null,
      meetingDate: null,
      startTime: null,
      endTime: null,
      importanceLevels: ["LOW", "MEDIUM", "HIGH", "CRITICAL"],
      hasReminderOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      hasReminder: false,
      users: [],
      selectedUsers: [],
    };
  },

  computed: {
    buttonText() {
      return this.isEditingMeeting ? "Update Meeting" : "Create Meeting";
    },
  },

  methods: {
    requiredRule() {
      return requiredRule;
    },

    async addOrUpdateMeeting() {
      if (this.hasMissingFields()) {
        this.displayError("Please fill in all required fields");
        return;
      }
      this.isEditingMeeting
        ? await this.editMeeting()
        : await this.addMeeting();
    },

    hasMissingFields() {
      return (
        !this.startTime || !this.endTime || !this.title || !this.meetingDate
      );
    },

    displayError(message) {
      this.message = message;
      this.error = true;
    },

    async editMeeting() {
      if (await this.$refs.edit_meeting_form.validate()) {
        const form = this.buildMeetingForm();
        const response = unifiedResponseHandler(
          await meetingService.editMeeting(form),
        );
        response.success
          ? this.handleSuccess("Meeting has been edited successfully")
          : this.displayError(response.message);
      }
    },

    async addMeeting() {
      if (await this.$refs.edit_meeting_form.validate()) {
        const form = this.buildMeetingForm();
        const response = unifiedResponseHandler(
          await meetingService.addMeeting(form),
        );
        if (response.success) {
          this.handleSuccess(
            "Meeting has been added successfully",
            response.data,
          );
        } else {
          this.displayError(response.message);
        }
      }
    },

    buildMeetingForm() {
      return {
        id: this.id,
        title: this.title,
        note: this.note,
        importanceLevel: this.selectedImportanceLevel,
        startTime: this.formatDateTime(this.meetingDate, this.startTime),
        endTime: this.formatDateTime(this.meetingDate, this.endTime),
        hasReminder: this.hasReminder,
        userIds: this.selectedUsers,
      };
    },

    async getUsers() {
      const response = unifiedResponseHandler(await userService.getUsers());
      this.users = response.success ? response.data : [];
    },

    setMeetingToEditVariables(newMeeting) {
      if (!newMeeting) return;
      this.isEditingMeeting = true;
      Object.assign(this, {
        meeting: newMeeting,
        id: newMeeting.id,
        title: newMeeting.title,
        selectedImportanceLevel: newMeeting.importanceLevel,
        note: newMeeting.note,
        meetingDate: newMeeting.startTime.slice(0, 10),
        startTime: this.extractTime(newMeeting.startTime),
        endTime: this.extractTime(newMeeting.endTime),
        hasReminder: newMeeting.hasReminder,
        selectedUsers: newMeeting.users.map((user) => user.id),
      });
    },

    extractTime(dateTime) {
      return {
        hours: dateTime.slice(11, 13),
        minutes: dateTime.slice(14, 16),
        seconds: "00",
      };
    },

    formatDateString(isoString) {
      const date = new Date(isoString);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    },

    formatDateTime(date, time) {
      return `${this.formatDateString(date)} ${this.formatTimeWithZeroPadding(time)}`;
    },

    formatTimeWithZeroPadding({ hours, minutes }) {
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    },

    handleSuccess(message, data = null) {
      this.message = message;
      this.success = true;
      if (data) this.setMeetingToEditVariables(data);
      emitter.emit("successful-meeting-operation");
    },
  },

  async mounted() {
    this.setMeetingToEditVariables(this.meetingToEdit);
    await this.getUsers();
  },
};
</script>

<style scoped></style>
