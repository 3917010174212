<template>
  <v-container fluid>
    <h3 class="font-weight-medium">Meetings</h3>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="createMeeting"
          icon="mdi-plus"
          class="mr-2"
        ></v-btn>
        <v-btn color="button_color" icon="mdi-export">
          <download-excel :data="excelOutput">
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <VCalendar
      ref="calendar"
      v-model="currentDate"
      :events="generateCalenderObjects()"
      :view-mode="viewMode"
      :weekdays="weekdays"
      hide-week-number
      class="text-md-h4 mt-4"
    >
      <template v-slot:header>
        <div
          class="d-flex justify-space-between align-center pa-2 bg-grey-lighten-2 rounded-t"
        >
          <v-btn @click="goToToday" color="button_color" variant="tonal">
            Today
          </v-btn>
          <span style="font-size: 20px">{{
            currentDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })
          }}</span>
          <div class="d-flex align-center">
            <v-btn
              @click="changeMonth(-1)"
              variant="text"
              border
              class="mx-1 text-none bg-transparent"
            >
              Prev Month
            </v-btn>
            <v-btn
              variant="text"
              @click="changeMonth(1)"
              border
              class="mx-1 text-none"
            >
              Next Month
            </v-btn>
          </div>
        </div>
      </template>

      <template v-slot:event="{ event, attrs }">
        <v-chip
          v-bind="attrs"
          class="d-inline-flex align-center text-caption"
          outlined
          :color="event.color"
          @click="handleEventClick(event)"
        >
          <v-badge dot inline :color="event.color"></v-badge>
          {{ event.title }}
        </v-chip>
      </template>
    </VCalendar>
    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="meetingDetailsModal" width="auto">
      <meeting-details-modal
        :meeting="this.clickedMeeting"
      ></meeting-details-modal>
    </v-dialog>
    <v-dialog v-model="createMeetingModal" max-width="600">
      <create-meeting-modal
        :meeting-to-edit="meetingToEdit"
      ></create-meeting-modal>
    </v-dialog>
  </v-container>
</template>

<script>
import meetingService from "@/services/meeting.service.js";
import { unifiedResponseHandler } from "@/utils/helpers.js";
import MeetingDetailsModal from "@/components/HomeManagement/MyDashboard/meeting-details-modal.vue";
import emitter from "@/plugins/mitt.js";
import CreateMeetingModal from "@/components/HomeManagement/MyDashboard/create-meeting-modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";

export default {
  name: "meeting_v01",
  components: {
    Success_Modal,
    Error_Modal,
    CreateMeetingModal,
    MeetingDetailsModal,
  },
  data: () => ({
    viewMode: "month",
    viewModes: ["month"],
    weekdays: [6, 0, 1, 2, 3, 4, 5],
    currentDate: new Date(),
    events: [],
    message: "",
    excelOutput: [],
    error: false,
    success: false,
    clickedMeeting: null,
    meetingDetailsModal: false,
    createMeetingModal: false,
    meetingToEdit: null,
  }),

  async mounted() {
    await this.getAllMeetings();
    this.generateCalenderObjects();
    this.createOutputData();
    emitter.on("edit-meeting", (value) => {
      this.meetingDetailsModal = false;
      this.editMeeting(value);
    });
    emitter.on("successful-meeting-operation", () => {
      this.getAllMeetings();
    });
    emitter.on("delete-meeting", (value) => {
      this.deleteMeeting(value);
    });
  },

  methods: {
    generateCalenderObjects() {
      const generatedEvents = [];
      for (let i = 0; i < this.events.length; i++) {
        generatedEvents.push({
          id: i,
          title: this.events[i].title,
          start: new Date(this.events[i].startTime),
          end: new Date(this.events[i].endTime),
          color: this.getColor(this.events[i].importanceLevel),
        });
      }
      return generatedEvents;
    },

    async getAllMeetings() {
      let response = unifiedResponseHandler(
        await meetingService.getAllMeetings(),
      );
      if (response.success) this.events = response.data;
    },

    createOutputData() {
      this.excelOutput = this.events.map((event) => {
        return {
          Id: event.id,
          Title: event.title,
          Note: event.note,
          StartTime: event.startTime,
          EndTime: event.endTime,
          ImportanceLevel: event.importanceLevel,
          CreatedBy: event.createdBy.firstName + " " + event.createdBy.lastName,
        };
      });
    },

    async deleteMeeting(id) {
      let response = unifiedResponseHandler(
        await meetingService.deleteMeeting(id),
      );
      if (response.success) {
        this.message = response.data;
        this.success = true;
        await this.getAllMeetings();
      } else {
        this.message = response.message;
        this.error = true;
      }
    },

    getColor(importance) {
      if (importance === "LOW") return "blue";
      else if (importance === "MEDIUM") return "green";
      else if (importance === "HIGH") return "#fcc603";
      else if (importance === "CRITICAL") return "red";
    },

    handleEventClick(event) {
      this.clickedMeeting = this.events[event.id];
      this.meetingDetailsModal = true;
    },

    createMeeting() {
      this.meetingToEdit = null;
      this.createMeetingModal = true;
    },

    editMeeting(meeting) {
      this.meetingToEdit = meeting;
      this.createMeetingModal = true;
    },

    goToToday() {
      this.currentDate = new Date();
    },

    changeMonth(direction) {
      const newDate = new Date(this.currentDate);
      newDate.setMonth(newDate.getMonth() + direction);
      this.currentDate = newDate;
    },
  },
};
</script>

<style scoped></style>
