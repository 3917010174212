<template>
  <v-row>
    <v-col cols="12" md="4" sm="8">
      <div class="d-flex">
        <v-text-field
            placeholder="Search"
            v-model="searchValue"
        >
          <template v-slot:prepend-inner>
            <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
            >
            </v-icon>
          </template>
        </v-text-field>
      </div>
    </v-col>

    <v-col class="d-flex justify-end">
      <v-btn color="button_color" @click="addOrEditAccount(null)" icon="mdi-plus" class="ml-2">
      </v-btn>
      <v-btn color="button_color" icon="mdi-export" class=" ml-2">
        <download-excel :data="thirdPartyAccounts">
          <v-icon>mdi-export</v-icon>
        </download-excel>
      </v-btn>
    </v-col>
  </v-row>
  <EasyDataTable
      :headers="headers"
      :items="thirdPartyAccounts"
      :search-field="searchField"
      :search-value="searchValue"
      alternating
      border-cell
      body-text-direction="right"
      header-text-direction="right"
      class="custom-data-table">
    <template v-if="!isSimpleAccountFormNeeded" #item-edit="item">
      <v-icon @click="addOrEditAccount(item)">mdi-pencil</v-icon>
    </template>
  </EasyDataTable>
  <v-dialog v-model="simpleAccountFormModal" width="auto">
    <simple-new-account-form-modal :chosen_third_party="this.chosen_third_party"></simple-new-account-form-modal>
  </v-dialog>
  <v-dialog v-model="accountFormModal" width="auto">
    <New-account-form-modal :chosen_third_party="this.chosen_third_party"
                            :chosen_account="this.chosenAccountForEdit"></New-account-form-modal>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import third_partyService from "@/services/third_party.service";
import {formatNumber, unifiedResponseHandler} from "@/utils/helpers.js";
import SimpleNewAccountFormModal from "@/components/Modals/Simple_new_account_form_modal.vue";
import emitter from "@/plugins/mitt.js";
import NewAccountFormModal from "@/components/Modals/New_account_form_modal.vue";

export default {
  name: "AccountsTable",
  components: {NewAccountFormModal, SimpleNewAccountFormModal},
  data() {
    return {
      headers: [
        {text: "Coffer Name", value: "cofferName"},
        {text: "Third Party Full Name", value: "thirdParty.fullName"},
        {text: "Currency", value: "currency.symbol"},
        {text: "Ledger Code", value: "ledgerCode"},
        {text: "In Transit Credit", value: "inTransitCreditAmount"},
        {text: "In Transit Debit", value: "inTransitDebitAmount"},
        {text: "Actual Amount", value: "actualAmount"},
      ],
      searchField: "",
      searchValue: "",
      thirdPartyAccounts: [],
      loading: false,
      chosenAccountForEdit: null,
      simpleAccountFormModal: false,
      accountFormModal: false,
    };
  },
  async mounted() {
    this.loading = true;
    await this.getThirdPartyAccounts(this.chosen_third_party.id);
    this.loading = false;
    if (!this.isSimpleAccountFormNeeded) {
      this.headers.push({text: "Edit", value: "Edit"});
    }
    emitter.on("successAccountOperation", () => {
      this.getThirdPartyAccounts(this.chosen_third_party.id);
    });
  },
  methods: {
    async getThirdPartyAccounts(id) {
      let response = unifiedResponseHandler(await third_partyService.getThirdPartyAccounts(id));
      if (response.success) {
        this.thirdPartyAccounts = response.data;
        this.thirdPartyAccounts = this.thirdPartyAccounts.map((account) => {
          return {
            ...account,
            actualAmount: formatNumber(account.actualAmount),
            inTransitCreditAmount: formatNumber(account.inTransitCreditAmount),
            inTransitDebitAmount: formatNumber(account.inTransitDebitAmount),
          }
        })
      } else {
        if (response.message !== "No Account is created for this Third Party") {
          this.error = true;
        }
      }
    },
    addOrEditAccount(account) {
      if (account)
        this.chosenAccountForEdit = account;
      else
        this.chosenAccountForEdit = null;

      if (this.isSimpleAccountFormNeeded) {
        this.simpleAccountFormModal = true;
        this.accountFormModal = false;
      } else {
        this.simpleAccountFormModal = false;
        this.accountFormModal = true;
      }
      console.log(this.chosenAccountForEdit);
    }

  },
  computed: {
    ...mapGetters(["chosen_third_party"]),
    isSimpleAccountFormNeeded() {
      if (!this.chosen_third_party)
        return false;
      let type = this.chosen_third_party.thirdPartyType
      return type === "CUSTOMER" || type === "INCOME_CENTER"
          || type === "COST_CENTER" || type === "SAFE_BOX";
    }
  },
};
</script>
