<template>
  <div>
    <v-container fluid>
      <h5>Company Database:</h5>
      <v-tabs
        fixed-tabs
        bg-color=""
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
      >
        <v-tab @click="step = 'info'"> company info </v-tab>
        <v-tab @click="step = 'contacts'"> {{ $t("contacts") }} </v-tab>
        <v-tab @click="step = 'files'"> linked files </v-tab>
      </v-tabs>
      <v-form
        class="mt-4"
        v-if="step === 'info'"
        ref="new_company_database_form"
      >
        <v-row>
          <v-col md="6" sm="12" xm="12">
            <v-text-field
              v-model="fullName"
              :rules="requiredRule"
              label="Company Name"
              required
              clearable
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailNotRequiredRule"
              label="Company Email"
            ></v-text-field>

            <v-text-field v-model="activity" label="Activity"></v-text-field>

            <v-text-field
              v-model="officeAddress"
              label="Company Address"
            ></v-text-field>

            <v-col cols="3" md="3" sm="3" xs="3" style="display: inline-block">
              <v-autocomplete
                label="Intl. Code"
                v-model="internationalCodePhoneNumber"
                :items="countries"
                filled
                return-object
                item-title="name"
                item-value="internationalCode"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props">
                    <template v-slot:append>
                      (+{{ item.raw.internationalCode }})
                      <country-flag
                        :country="item.raw.code"
                        style="margin-left: 5px"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="9" md="9" sm="9" xs="9" style="display: inline-block">
              <v-text-field
                v-model="phoneNumber"
                label="Phone Number"
                :rules="phoneCellNotRequired"
              ></v-text-field>
            </v-col>
          </v-col>
          <v-col md="6" sm="12" xm="12">
            <v-text-field v-model="url" label="URL" required></v-text-field>

            <v-autocomplete
              label="Select Country"
              v-model="selectedCountry"
              :items="countries"
              item-title="name"
              item-value="id"
              filled
              return-object
              :rules="requiredRule"
            >
              <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props">
                  <template v-slot:append>
                    <country-flag :country="item.raw.code" />
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>

            <v-tab
              color="button_color"
              @click="individualListModal = true"
              prepend-icon="mdi-magnify"
              >select</v-tab
            >

            <v-text-field
              v-model="introducerFullName"
              label="Introducer"
              @keyup.backspace="clearIntroducer"
              @keydown="handleKeyDown"
            ></v-text-field>

            <v-textarea class="mt-4" v-model="note" label="Note"></v-textarea>
          </v-col>
        </v-row>

        <v-row justify="end" class="mt-4">
          <v-btn
            class="mr-4"
            color="button_color"
            v-if="!is_editing_comapny"
            @click="addCompany"
            >Create Campany Database</v-btn
          >
          <v-btn class="mr-4" v-else @click="addCompany" color="button_color"
            >Edit Campany Database</v-btn
          >
        </v-row>
      </v-form>

      <v-form v-if="step === 'contacts'">
        <v-container fluid>
          <v-sheet class="m-12" width="300">
            <v-form ref="add_contact_form">
              <v-tab
                color="button_color"
                @click="individualListModal = true"
                prepend-icon="mdi-magnify"
                >select</v-tab
              >

              <v-text-field
                v-model="newContactFullName"
                label="New Contact"
                item-title="firstName"
                item-value="id"
                return-object
                filled
                @keyup.backspace="clearNewContact"
                @keydown="handleKeyDown"
                :rules="requiredRule"
              ></v-text-field>

              <span>{{ message }}</span>
              <div class="d-flex top-20">
                <v-btn color="button_color" @click="addContact()">
                  Add Contact
                </v-btn>
              </div>
            </v-form>
          </v-sheet>
          <v-divider></v-divider>
          <v-toolbar-title>{{ $t("contacts") }}:</v-toolbar-title>
          <v-table height="300px">
            <thead>
              <tr>
                <th class="text-left">First Name</th>
                <th class="text-left">Last Name</th>
                <th class="text-left">E mail</th>
                <th class="text-left">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(contact, index) in contacts" :key="index">
                <td>{{ contact.firstName }}</td>
                <td>{{ contact.lastName }}</td>
                <td>{{ contact.email }}</td>
                <td><v-icon>mdi-delete</v-icon></td>
              </tr>
            </tbody>
          </v-table>
        </v-container>
      </v-form>
      <v-row class="mt-4" v-if="step === 'files'">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-form ref="file_upload_form">
                <v-file-input
                  v-model="selectedFiles"
                  label="Select File"
                  multiple
                  show-size
                  :accept="acceptedFileTypes"
                  :rules="[multipleFileRequiredRule, sizeRule]"
                >
                </v-file-input>
              </v-form>
            </v-col>
            <v-col cols="auto">
              <v-btn color="button_color" @click="uploadFiles">Upload</v-btn>
              <span>
                {{ message }}
              </span>
            </v-col>
            <v-col cols="auto">
              <p v-if="selectedFiles.length === 0" class="mt-4">
                please select a file
              </p>
            </v-col>
          </v-row>
          <v-list>
            <v-list-item v-for="(file, index) in selectedFiles" :key="index">
              <v-icon @click="remove(file)">mdi-delete</v-icon> {{ file.name }}
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-toolbar-title> all files:</v-toolbar-title>
          <v-list class="mt-4">
            <v-list-item v-for="(file, index) in files" :key="index">
              <v-icon @click="downLoad(file)">mdi-download</v-icon>
              {{ file.name }}
            </v-list-item>
          </v-list>
        </v-container>
      </v-row>
    </v-container>

    <v-dialog v-model="success">
      <div class="success-dialog">
        <div class="success-left">
          <div class="green-circle">
            <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M50 100 L80 130 L150 50"
                stroke="white"
                stroke-width="25"
                fill="transparent"
                class="success-tick"
              />
            </svg>
          </div>
        </div>
        <div class="success-right">
          <span @click="success = false" style="text-align: right">✖</span>
          <h4>Success</h4>
          <p class="success-message">{{ message }}</p>
          <button class="success-close-button" @click="success = false">
            Close
          </button>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="error">
      <div class="error-dialog">
        <div class="error-left">
          <div class="red-icon"></div>
        </div>
        <div class="error-right">
          <span @click="error = false" style="text-align: right">✖</span>
          <h4>Error</h4>
          <p class="error-message">{{ message }}</p>
          <button class="close-button" @click="error = false">Close</button>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="individualListModal" width="auto">
      <Individual_List_Modal_V01></Individual_List_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import CountryService from "@/services/country.service.js";
import CountryFlag from "vue-country-flag-next";
import {
  requiredRule,
  phoneNullAllowedRule,
  emailRule,
  multipleFileRequiredRule,
  sizeRule,
  numericRule,
  phoneCellRequired,
  emailNotRequiredRule,
  phoneCellNotRequired,
} from "@/utils/rules.js";
import {
  handleKeyDown,
  combineCodeAndNumber,
  formatNumber,
} from "@/utils/helpers.js";
// httpResponseHandler
import { lengthRule } from "@/utils/rules.js";
import { ACCEPTED_FILE_TYPES } from "@/config/constants.js";

import Individual_List_Modal_V01 from "../../Modals/Individual_List_Modal_V01.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import emitter from "@/plugins/mitt.js";
import { convertByteCode } from "@/utils/helpers.js";
import { unifiedResponseHandler } from "@/utils/helpers.js";

export default {
  name: "company_database_v01",
  components: {
    Individual_List_Modal_V01,
    CountryFlag,
  },
  data() {
    return {
      id: null,
      step: "info",
      fullName: null,
      internationalCodePhoneNumber: null,
      success: false,
      error: false,
      message: null,
      emailNotRequiredRule: emailNotRequiredRule,
      activity: null,
      introducer: null,
      email: null,
      officeAddress: null,
      note: null,
      phoneNumber: null,
      customerClubJoinDate: null,
      emailRule: emailRule,
      requiredRule: requiredRule,
      phoneNullAllowedRule: phoneNullAllowedRule,
      newIndividualModal: false,
      individualListModal: false,
      accountTypeListModal: false,
      phoneCellRequired: phoneCellRequired,
      countries: [],
      thirdPartyTypes: [
        { name: "CUSTOMER", id: 0 },
        { name: "TRUSTEE", id: 1 },
        { name: "PAYER/PAYEE", id: 2 },
      ],
      url: null,
      selectedCountry: null,
      selected: null,
      selectedType: null,
      contacts: [],
      bankAccounts: [],
      selectedFiles: [],
      files: [],
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      accountNumber: null,
      IBANNumber: null,
      swiftCode: null,
      currencySymbol: null,
      bankName: null,
      bankBranch: null,
      selectedCurrency: null,
      selectedTrusteeCurrency: null,
      accountType: null,
      multipleFileRequiredRule: multipleFileRequiredRule,
      phoneCellNotRequired: phoneCellNotRequired,
      sizeRule: sizeRule,
      lengthRule: lengthRule,
      numericRule: numericRule,
      cofferName: null,
      bankAccountName: null,
      bankAccountNumber: null,
      iban: null,
      bicOrSwiftCode: null,
      bankAccountOwnerFullName: null,
      thirdPartyAccounts: [],
      newContact: null,
    };
  },
  methods: {
    formatNumber,
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearIntroducer() {
      console.log("clean");
      this.introducer = null;
    },
    clearNewContact() {
      console.log("clean");
      this.newContact = null;
    },
    clearAccountType() {
      this.accountType = null;
    },
    async addCompany() {
      const { valid } = await this.$refs.new_company_database_form.validate();
      if (valid) {
        let form = {
          id: this.id,
          fullName: this.fullName,
          countryId: this.selectedCountry.id,
          activity: this.activity,
          introducerId: this.introducerId,
          email: this.email,
          url: this.url,
          officeAddress: this.officeAddress,
          phoneNumber: this.phoneNumberWithCode,
          note: this.note,
        };
        console.log(form);
        // let response = null;
        // if (!this.is_editing_comapny) {
        //   response = messageResultHandler(
        //   );
        // } else {
        //   response = messageResultHandler(
        //   );
        // }
        // this.message = response.message;
        // if (response.success) {
        //   this.success = true;
        //   this.$store.dispatch("setChosenThirdParty", response.data);
        // } else {
        //   this.error = true;
        // }
      }
    },
    async addContact() {
      let { valid } = await this.$refs.add_contact_form.validate();
      console.log("validation", valid);
      if (valid) {
        // if (!this.chosen_third_party) {
        //   this.error = true;
        //   this.message = "the company database is not loaded";
        //   return;
        // }
        let form = {
          identity: this.newContactFullName,
          thirdPartyId: this.newContactId,
          //   identityId: this.newContactId,
          //   thirdPartyId: this.chosen_third_party.id,
        };
        console.log("add contact", form);

        // if (valid) {
        //   let response = messageResultHandler(
        //     await third_partyService.addContact(form)
        //   );
        //   this.message = response.message;
        //   if (response.success) {
        //     this.success = true;

        //     this.contacts.push(
        //       response.data
        //     );
        //   } else {
        //     this.error = true;
        //   }
        // }
      }
    },
    async getCountries() {
      let response = unifiedResponseHandler(
        await CountryService.getCountries(),
      );
      if (response.success) {
        this.countries = response.data;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async uploadFiles() {
      let { valid } = await this.$refs.file_upload_form.validate();
      console.log("validate", valid);
      if (valid) {
        // if (!this.chosen_third_party) {
        //   this.message = "the third party is not loaded";
        //   this.error = true;
        //   return;
        // }

        // let response = messageResultHandler(
        //   await third_partyService.attachFiles(
        //     this.chosen_third_party.id,
        //     this.selectedFiles[0]
        //   )
        // );
        // this.message = response.message;
        // if (response.success) {
        //   this.success = true;
        //   this.selectedFiles.length = 0;
        //   await this.getFiles(this.chosen_third_party.id);
        // } else {
        //   this.message = "File size should be less than 5MB";
        //   this.error = true;
        // }
        console.log("hiiii");
      }
    },
    remove(file) {
      this.selectedFiles = this.selectedFiles.filter((item) => item !== file);
    },
    async downLoad(file) {
      this.success = await convertByteCode(file);
    },
    // async getFiles(id) {
    //   let response = messageResultHandler(
    //     await third_partyService.getThirdPartyFiles(id)
    //   );
    //   if (response.success) {
    //     this.files = response.data;
    //   } else {
    //     this.message = response.message;
    //     this.error = true;
    //   }
    // },
  },
  async mounted() {
    await this.getCountries();

    // this.getCurrencies();
    emitter.on("new_individual_id", (value) => {
      if (this.step === "info") {
        this.introducer = value;
      }
      if (this.step === "contacts") {
        this.newContact = value;
      }

      this.individualListModal = false;
    });
  },
  beforeUnmount() {
    this.newIndividualModal = false;
  },
  watch: {
    step() {
      this.message = "";
    },

    error(newValue) {
      if (!newValue) {
        this.message = "";
      }
    },
    success(newValue) {
      if (!newValue) {
        this.message = "";
      }
    },
  },
  computed: {
    isCustomer() {
      if (!this.chosen_third_party) {
        return false;
      } else {
        return this.chosen_third_party.thirdPartyType === "CUSTOMER";
      }
    },

    isSecondary() {
      if (!this.chosen_third_party) {
        return true;
      } else {
        if (this.chosen_third_party.thirdPartyType === "SECONDARY_THIRDPARTY") {
          return true;
        } else {
          return false;
        }
      }
    },

    newContactFullName() {
      if (this.newContact) {
        return this.newContact.firstName + " " + this.newContact.lastName;
      } else {
        return null;
      }
    },

    newContactId() {
      if (this.newContact) {
        return this.newContact.id;
      } else {
        return null;
      }
    },

    introducerFullName() {
      if (this.introducer) {
        return this.introducer.firstName + " " + this.introducer.lastName;
      } else {
        return null;
      }
    },

    introducerId() {
      if (this.introducer) {
        return this.introducer.id;
      } else {
        return null;
      }
    },
    accountTypeName() {
      if (this.accountType) {
        return this.accountType.accountGroup + this.accountType.ledgerCode;
      } else {
        return null;
      }
    },
    accountTypeId() {
      if (this.accountType) {
        return this.accountType.id;
      } else {
        return null;
      }
    },

    phoneNumberWithCode() {
      let response = combineCodeAndNumber(
        this.internationalCodePhoneNumber,
        this.phoneNumber,
      );
      return response;
    },
  },
};
</script>
