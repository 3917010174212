<template>
  <v-container fluid style="background-color: white; min-height: 300px; width: 700px; border-radius: 5px;">
    <v-card-title class="font-weight-bold text-h5" style="text-align: center">
      Reminder Details
    </v-card-title>

    <v-card-text class="text-body-1">
      <v-row class="mt-2">
        <v-col cols="6">
          <strong class="font-weight-bold">Title:</strong> {{ reminder.title }}
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">Type:</strong>
          <v-chip :color="getTypeColor(reminder.type)" dark>
            {{ reminder.type }}
          </v-chip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">Active:</strong>
          <br>
          <v-chip size="large" :color="reminder.isActive ? 'green' : 'red'" dark>
            {{ reminder.isActive ? 'Yes' : 'No' }}
          </v-chip>
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">
            {{ reminder.type === 'SCHEDULED' ? 'Early Reminder:' : 'Repeat Interval:' }}
          </strong>
          <div>
            {{ reminder.type === 'SCHEDULED' ? formatInterval(reminder.earlyReminder) : formatInterval(reminder.repeatInterval) }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">Alarm Date:</strong>
          <div>{{ reminder.alarmDate ? formatDate(reminder.alarmDate) : this.calculateNextAlarm(reminder.createDate , reminder.repeatInterval) }}</div>
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">Creation Date:</strong>
          <div>{{ formatDate(reminder.createDate) }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">Time Remaining:</strong>
          <div>{{ calculateRemainingTime(reminder) }}</div>
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">Owner:</strong>
          <br>
          <v-chip :style="{ color: 'blue' }">{{ reminder.owner.firstName }}</v-chip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <strong class="font-weight-bold">Note:</strong>
          <div>{{ reminder.note }}</div>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>
      <v-row justify="end">
        <v-btn variant="outlined" class="mr-1 ma-3" color="button_color" @click="editReminder(reminder)">
          Edit Reminder
        </v-btn>
        <v-btn class="mr-9 ma-3" color="red" @click="deleteReminder(reminder)">
          Delete
        </v-btn>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
import emitter from "@/plugins/mitt.js";

export default {
  name: "ReminderDetailsModal",
  props: ["reminder"],
  methods: {
    getTypeColor(type) {
      return type === "SCHEDULED" ? "blue" : "green";
    },

    calculateNextAlarm(createTimeStr, intervalInMinutes) {
      let nextAlarm = new Date(createTimeStr);
      const now = new Date();
      while (nextAlarm <= now) {
        if(intervalInMinutes === 518400)
          nextAlarm.setFullYear(nextAlarm.getFullYear() + 1)
        else
        nextAlarm.setMinutes(nextAlarm.getMinutes() + intervalInMinutes);
      }
      return this.formatDate(nextAlarm);
    },

    formatDate(dateStr) {
      const date = new Date(dateStr);
      const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" };
      return date.toLocaleDateString("en-US", options);
    },
    formatInterval(interval) {
      if (!interval) return "N/A";
      const minutesInHour = 60;
      const minutesInDay = minutesInHour * 24;
      const minutesInWeek = minutesInDay * 7;
      const minutesInMonth = minutesInDay * 30;
      const minutesInYear = minutesInMonth * 12;
      if (interval >= minutesInYear) {
        const years = interval / minutesInYear;
        return `${years} year${years > 1 ? 's' : ''}`;
      } else if (interval >= minutesInMonth) {
        const months = interval / minutesInMonth;
        return `${months} month${months > 1 ? 's' : ''}`;
      } else if (interval >= minutesInWeek) {
        const weeks = interval / minutesInWeek;
        return `${weeks} week${weeks > 1 ? 's' : ''}`;
      } else if (interval >= minutesInDay) {
        const days = interval / minutesInDay;
        return `${days} day${days > 1 ? 's' : ''}`;
      } else if (interval >= minutesInHour) {
        const hours = interval / minutesInHour;
        return `${hours} hour${hours > 1 ? 's' : ''}`;
      } else {
        return `${interval} minute${interval > 1 ? 's' : ''}`;
      }
    },

    calculateRemainingTime(reminder) {
      const now = new Date();
      const alarmDate = new Date(reminder.alarmDate);
      let diffMs;
      if (reminder.type === "SCHEDULED") {
        diffMs = alarmDate - now;
      } else if (reminder.type === "CONTINUOUS") {
        const repeatIntervalMs = reminder.repeatInterval * 60 * 1000;
        const nextInterval = now.getTime() + repeatIntervalMs;
        diffMs = nextInterval - now;
      } else {
        return "N/A";
      }
      if (diffMs <= 0) return "Time's up!";
      const timeUnits = {
        years: Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365)),
        months: Math.floor((diffMs % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30)),
        days: Math.floor((diffMs % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)),
      };
      // eslint-disable-next-line no-unused-vars
      const nonZeroUnits = Object.entries(timeUnits).filter(([_, value]) => value > 0);
      const result = nonZeroUnits.slice(0, 2).map(([unit, value]) => `${value} ${unit}`).join(" and ");
      return result || "Less than a minute";
    },
    editReminder(reminder) {
      emitter.emit("edit-reminder", reminder);
    },
    deleteReminder(reminder) {
      emitter.emit("delete-reminder", reminder.id);
    },
  },
};
</script>
