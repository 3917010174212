<template>
  <div>
    <v-form ref="form">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6" sm="12" xs="12">
            <v-text-field
                v-model="firstName"
                :rules="requiredRule"
                label="First Name"
                required
            ></v-text-field>
            <v-text-field
                v-model="lastName"
                :rules="requiredRule"
                label="Last Name"
                required
            ></v-text-field>
            <v-text-field
                v-model="identificationCode"
                label="Identification Code"
                required
            ></v-text-field>
            <v-text-field
                v-if="!is_editing_user"
                :type="password"
                v-model="password"
                :rules="requiredRule"
                label="Password"
                required
            ></v-text-field>
            <v-text-field
                v-model="email"
                :rules="emailRule"
                label="Email Address"
                required
            ></v-text-field>
            <v-text-field v-model="address" label="Address"></v-text-field>
            <v-text-field
                v-model="jobPosition"
                label="Job Position"
            ></v-text-field>

            <v-textarea v-model="note" label="Note"></v-textarea>
          </v-col>
          <v-col cols="12" md="6" sm="12" xs="12">
            <v-col cols="3" md="3" sm="3" xs="3" style="display: inline-block">
              <v-autocomplete
                  label="Intl. Code"
                  v-model="internationalCodePhoneNumber"
                  :items="countries"
                  filled
                  return-object
                  item-title="name"
                  item-value="internationalCode"
              >
                <template v-slot:item="{ props,item }">
                  <v-list-item v-bind="props">
                    <template v-slot:append>
                      (+{{ item.raw.internationalCode }})
                      <country-flag
                          :country="item.raw.code"
                          style="margin-left: 5px"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="9" md="9" sm="9" xs="9" style="display: inline-block">
              <v-text-field
                  :rules="phoneCellNotRequired"
                  v-model="phoneNumber"
                  label="Phone Number"
              ></v-text-field>
            </v-col>
            <v-col cols="3" md="3" sm="3" xs="3" style="display: inline-block">
              <v-autocomplete
                  label="Intl. Code"
                  v-model="internationalCodeCellPhoneNumber"
                  :rules="requiredRule"
                  :items="countries"
                  filled
                  return-object
                  item-title="name"
                  item-value="internationalCode"
              >
                <template v-slot:item="{ props,item }">
                  <v-list-item v-bind="props">
                    <template v-slot:append>
                      (+{{ item.raw.internationalCode }})
                      <country-flag
                          :country="item.raw.code"
                          style="margin-left: 5px"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="9" md="9" sm="9" xs="9" style="display: inline-block">
              <v-text-field
                  v-model="cellPhoneNumber"
                  label="Cellphone Number"
                  :rules="phoneCellRequired"
                  required
              >
              </v-text-field>
            </v-col>
            <v-text-field
                v-model="accountingCode"
                :rules="numericRule"
                label="Accounting Code"
                required
            ></v-text-field>

            <label for="date-picker" style="color: black">Birth Day</label>
            <VueDatePicker
                auto-apply="true"
                id="date-picker"
                v-model="birthDate"
                model-type="yyyy-MM-dd"
                :show-timepicker="false"
            ></VueDatePicker>

            <v-checkbox
                v-if="!is_editing_user"
                v-model="enabled"
                label="enabled"
                value="true"
            ></v-checkbox>
            <v-autocomplete
                v-if="!is_editing_user"
                label="User Role"
                v-model="userRole"
                :items="['ADMIN', 'OPERATION_MANAGER', 'OPERATION_TEAM', 'FINANCE_MANAGER', 'FINANCE_TEAM', 'OFFICE_MANAGER','OFFICE_TEAM']"
                filled
                return-object
                :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-btn color="button_color" @click="cancel"> Cancel</v-btn>
          <v-btn
              color="button_color"
              class="mx-4"
              v-if="!is_editing_user"
              @click="addUser"
          >
            Create User
          </v-btn>
          <v-btn color="button_color" class="mx-4" v-else @click="editUser">
            Edit User
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="error">
      <div class="error-dialog">
        <div class="error-left">
          <div class="red-icon"></div>
        </div>
        <div class="error-right">
          <span  @click="error = false" style="text-align: right;">✖</span>
          <h4>Error</h4>
          <p class="error-message">{{ message }}</p>
          <button class="close-button" @click="error = false">Close</button>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="success">
      <div class="success-dialog">
        <div class="success-left">
          <div class="green-circle">
            <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
              <path d="M50 100 L80 130 L150 50" stroke="white" stroke-width="25" fill="transparent"
                    class="success-tick"/>
            </svg>
          </div>
        </div>
        <div class="success-right">
          <span  @click="success = false" style="text-align: right;">✖</span>
          <h4>Success</h4>
          <p class="success-message"> {{ message }}</p>
          <button class="success-close-button" @click="success = false">Close</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import UserService from "@/services/user.service";
// import emitter from "@/plugins/mitt";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import CountryService from "@/services/country.service";
import CountryFlag from "vue-country-flag-next";
import {
  combineCodeAndNumber,
  extractCodeAndPhone,
  findCountryByCode,
  unifiedResponseHandler,
  refreshEditUser,
  setPage,
} from "@/utils/helpers";
import {
  cellPhoneRule,
  emailRule,
  numericRule,
  phoneAndCellRule,
  phoneCellNotRequired,
  phoneCellRequired,
  phoneRule,
  requiredRule,
} from "@/utils/rules.js";
import {mapGetters} from "vuex";

export default {
  name: "new_user_v01",
  components: {
    VueDatePicker,
    CountryFlag,
    // VuePhoneNumberInput
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      address: null,
      jobPosition: null,
      accountingCode: null,
      phoneNumber: null,
      cellPhoneNumber: null,
      identificationCode: null,
      internationalCodePhoneNumber: null,
      internationalCodeCellPhoneNumber: null,
      phoneCellNotRequired: phoneCellNotRequired,
      countries: [],
      selectedCountry: null,
      enabled: false,
      note: null,
      birthDate: null,
      userRole: null,
      message: null,
      requiredRule: requiredRule,
      numericRule: numericRule,
      emailRule: emailRule,
      cellPhoneRule: cellPhoneRule,
      phoneRule: phoneRule,
      phoneAndCellRule: phoneAndCellRule,
      phoneCellRequired: phoneCellRequired,
      error: false,
      success: false,
    };
  },
  methods: {
    async addUser() {
      const {valid} = await this.$refs.form.validate();
      if (!this.userRole) {
        return;
      }
      if (valid) {
        let form = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          address: this.address,
          jobPosition: this.jobPosition,
          accountingCode: this.accountingCode,
          phoneNumber: this.phoneNumberWithCode,
          cellPhoneNumber: this.cellPhoneNumberWithCode,
          identificationCode: this.identificationCode,
          userRole: this.userRole,
          enabled: this.enabled,
          note: this.note,
          birthDate: this.birthDate,
        };
        console.log("the phone number form", form);
        let response = unifiedResponseHandler(await UserService.addUser(form));
        this.message = response.message;
        if (response.success) {
          this.success = true;
        } else {
          this.error = true;
        }
      }
    },
    async editUser() {
      const {valid} = await this.$refs.form.validate();
      if (valid) {
        let form = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          address: this.address,
          jobPosition: this.jobPosition,
          accountingCode: this.accountingCode,
          phoneNumber: this.phoneNumberWithCode,
          cellPhoneNumber: this.cellPhoneNumberWithCode,
          identificationCode: this.identificationCode,
          note: this.note,
          birthDate: this.birthDate,
        };
        let response = unifiedResponseHandler(await UserService.editUser(form));
        this.message = response.message;
        if (response.success) {
          this.success = true;
          // emitter.emit("edited_user_email", this.email);
          // setPage("users_list");
        } else {
          this.error = true;
        }
      }
    },
    async cancel() {
      setPage("users_list");
    },
    async getCountries() {
      let response = unifiedResponseHandler(await CountryService.getCountries());
      if (response.success) {
        this.countries = response.data;
      }
    },
  },
  beforeUnmount() {
    refreshEditUser();
  },
  async mounted() {
    await this.getCountries();
    if (this.is_editing_user) {
      this.firstName = this.chosen_user.firstName;
      this.lastName = this.chosen_user.lastName;
      this.email = this.chosen_user.email;
      this.password = this.chosen_user.password;
      this.address = this.chosen_user.address;
      this.jobPosition = this.chosen_user.jobPosition;
      this.accountingCode = this.chosen_user.accountingCode;

      if (this.chosen_user.phoneNumber) {
        this.phoneNumber = extractCodeAndPhone(
            this.chosen_user.phoneNumber
        ).phoneNumber;
        this.internationalCodePhoneNumber = findCountryByCode(
            this.countries,
            extractCodeAndPhone(this.chosen_user.phoneNumber).countryCode
        );
      }
      if (this.chosen_user.cellPhoneNumber) {
        this.cellPhoneNumber = extractCodeAndPhone(
            this.chosen_user.cellPhoneNumber
        ).phoneNumber;
        this.internationalCodeCellPhoneNumber = findCountryByCode(
            this.countries,
            extractCodeAndPhone(this.chosen_user.cellPhoneNumber).countryCode
        );
      }

      this.identificationCode = this.chosen_user.identificationCode;
      this.userRole = this.chosen_user.role;
      this.birthDate = this.chosen_user.birthDate;
    }
  },
  computed: {
    ...mapGetters(["chosen_user", "is_editing_user"]),
    phoneNumberWithCode() {
      return combineCodeAndNumber(
          this.internationalCodePhoneNumber,
          this.phoneNumber
      );
    },

    cellPhoneNumberWithCode() {
      return combineCodeAndNumber(
          this.internationalCodeCellPhoneNumber,
          this.cellPhoneNumber
      );
    },
  },
  watch: {
    success(newValue) {
      if (!newValue) {
        setPage("users");
      }
    },
  },
};
</script>
