function getEventTypeDisplay(transaction) {
    let transactionTypeDisplay = "";

    switch (transaction.eventType) {
        case "MONEY_TRANSFER":
            switch (transaction.moneyTransferRequestType) {
                case "CASH_RECEIVE":
                    transactionTypeDisplay = "Cash Receive";
                    break;
                case "CASH_SEND":
                    transactionTypeDisplay = "Cash Send";
                    break;
                case "BANK_RECEIVE":
                    transactionTypeDisplay = "Bank Receive";
                    break;
                case "BANK_SEND":
                    transactionTypeDisplay = "Bank Send";
                    break;
            }
            break;
        case "INTERNAL_TRANSFER":
            transactionTypeDisplay = "Internal";
            break;
        case "MANUAL_TRANSFER":
            transactionTypeDisplay = "Bill Payment";
            break;
        case "CRYPTO_DEAL":
            transactionTypeDisplay = "Crypto Deal";
            break;
        case "MANUAL_TRANSACTION":
            transactionTypeDisplay = "Manual";
            break;
        case "SAFE_BOX_OPERATION":
            transactionTypeDisplay = "Safe Box Operation"
            break;
        case "GROUP_DEAL":
            transactionTypeDisplay = "Group Deal";
            break;
        case "RECORD_SET":
            transactionTypeDisplay = "Journal Entry";
            break;
        default:
            transactionTypeDisplay = "Unknown";
    }

    return transactionTypeDisplay;
}

function getMoneyTransferRequestType(transaction) {
    let transactionTypeDisplay;

    switch (transaction) {
        case "CASH_RECEIVE":
            transactionTypeDisplay = "Cash Receive";
            break;
        case "CASH_SEND":
            transactionTypeDisplay = "Cash Send";
            break;
        case "BANK_RECEIVE":
            transactionTypeDisplay = "Bank Receive";
            break;
        case "BANK_SEND":
            transactionTypeDisplay = "Bank Send";
            break;
        default:
            transactionTypeDisplay = "Unknown";
    }

    return transactionTypeDisplay;
}

export {getEventTypeDisplay, getMoneyTransferRequestType};
