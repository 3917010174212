import { accesses } from "@/config/accesses";
import { authRole } from "./auth-header";

export const allowedSections = (sections) => {
  if (!sections) return Array.isArray(sections) ? [] : {};

  const secs = JSON.parse(JSON.stringify(sections));
  const role = authRole();

  if (Array.isArray(secs)) {
    for (let i = secs.length - 1; i >= 0; i--) {
      const key = secs[i];
      if (accesses[key] && !accesses[key].includes(role)) {
        secs.splice(i, 1);
      }
    }
  } else {
    for (const key of Object.keys(secs)) {
      if (accesses[key]) {
        if (Array.isArray(accesses[key])) {
          if (!accesses[key].includes(role)) {
            delete secs[key];
          }
        } else if (typeof accesses[key] === "object") {
          secs[key] = secs[key].filter(
            (subKey) =>
              accesses[key][subKey] === undefined ||
              accesses[key][subKey].includes(role),
          );
          if (secs[key].length === 0) {
            delete secs[key];
          }
        }
      }
    }
  }

  return secs;
};
