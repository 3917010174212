<template>
  <div>
    <v-card color="surface">
      <v-container v-if="!broken_page">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
                label="Search"
                v-model="searchValue"
            ></v-text-field>
          </v-col>
        </v-row>
        <EasyDataTable
            class="datatable-container"
            :headers="headers"
            :items="users"
            :search-field="searchField"
            :search-value="searchValue"
            alternating
            border-cell
        >
          <template #item-choose="item">
            <v-icon @click="chooseUser(item)">mdi-check-circle</v-icon>
          </template>
        </EasyDataTable>
      </v-container>
      <div v-else>
        <Broken_Page_V01></Broken_Page_V01>
      </div>
    </v-card>
  </div>
</template>
<script>
import {unifiedResponseHandler} from "@/utils/helpers";
import emitter from "@/plugins/mitt";
import Broken_Page_V01 from "../Broken_Page_V01.vue";
import userService from "@/services/user.service.js";

export default {
  name: "users_list_modal",
  components: {
    Broken_Page_V01,
  },
  data() {
    return {
      headers: [
        {text: 'Id', value: 'id'},
        {text: 'Last name', value: 'lastName'},
        {text: 'First name', value: 'firstName'},
        {text: 'Email', value: 'email'},
        {text: "Choose", value: "choose"},
      ],
      searchField: "",
      searchValue: "",
      message: null,
      broken_page: false,
      users: [],
    };
  },

  methods: {
    chooseUser(new_third_party) {
      emitter.emit("new_user", new_third_party);
    },

    async getUsers() {
      let callResponse = await userService.getUsers();
      let response;
      if (callResponse) {
        response = unifiedResponseHandler(callResponse);
        console.log(callResponse)
        if (response.success) {
          this.users = response.data
        } else {
          this.broken_page = true;
        }
      }
    },
  },

  async mounted() {
    await this.getUsers();
  },
};
</script>
