<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="font-weight-medium mb-2">Reminders</h3>

    <v-row>
      <v-col cols="12" md="4" sm="8">
        <div class="d-flex">
          <v-text-field placeholder="Search" v-model="searchValue">
            <template v-slot:prepend-inner>
              <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="addReminder"
          icon="mdi-plus"
          class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="reminders"
            ><v-icon>mdi-export</v-icon></download-excel
          >
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
        :headers="headers"
        :items="formattedReminders"
        :search-field="searchField"
        :search-value="searchValue"
        alternating
        border-cell
        body-text-direction="center"
        header-text-direction="center"
        class="custom-data-table"
    >
      <template #item-edit="item">
        <v-icon @click="ShowReminderDetails(item)">mdi-pencil</v-icon>
      </template>
      <template #item-status="item">
        <v-icon
            @click="changeReminderActiveStatus(item)"
            :color="item.isActive ? 'green' : 'red'"
            :size="36"
        >
          {{ !item.isActive ? "mdi-toggle-switch-off" : "mdi-toggle-switch" }}
        </v-icon>
      </template>
      <template #item-remaining-time="item">
        <div>{{ item.remainingTime }}</div>
      </template>
    </EasyDataTable>
  </v-container>
  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>
  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
  <v-dialog v-model="createReminderModal" width="auto">
    <create-reminder-modal
      :reminderToEdit="selectedReminder"
    ></create-reminder-modal>
  </v-dialog>
  <v-dialog v-model="reminderDetailsModal" width="auto">
    <reminder-details-modal
        :reminder="selectedReminder"
    ></reminder-details-modal>
  </v-dialog>
</template>

<script>
import { unifiedResponseHandler } from "@/utils/helpers";
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import emitter from "@/plugins/mitt";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import reminderService from "@/services/reminder.service.js";
import CreateReminderModal from "@/components/HomeManagement/MyDashboard/create-reminder-modal.vue";
import ReminderDetailsModal from "@/components/HomeManagement/MyDashboard/reminder-details-modal.vue";

export default {
  name: "reminder_V01",
  components: {
    ReminderDetailsModal,
    CreateReminderModal,
    Success_Modal,
    Error_Modal,
    Broken_Page_V01,
  },
  data() {
    return {
      headers: [
        { text: "Id", value: "id" },
        { text: "Type", value: "type" },
        { text: "Title", value: "title" },
        { text: "Remaining Time", value: "remainingTime" },
        { text: "Status", value: "status" },
        { text: "Edit/Details", value: "edit" },
      ],
      reminders: [],
      searchField: "",
      searchValue: "",
      broken_page: false,
      createReminderModal: false,
      reminderDetailsModal: false,
      success: false,
      error: false,
      message: null,
      selectedReminder: null,
    };
  },
  methods: {
    async getReminders() {
      let response = unifiedResponseHandler(
        await reminderService.getAllReminders(),
      );
      if (response.success) {
        this.reminders = response.data;
      } else {
        this.broken_page = true;
      }
    },
    ShowReminderDetails(item) {
      this.selectedReminder = item;
      this.reminderDetailsModal = true;
    },
    editReminder(item) {
      this.selectedReminder = item;
      this.createReminderModal = true;
    },
    addReminder() {
      this.selectedReminder = null;
      this.createReminderModal = true;
    },
    getRemainingTimeInMs(reminder) {
      const now = new Date();
      const alarmDate = new Date(reminder.alarmDate);

      if (reminder.type === "SCHEDULED") {
        return alarmDate - now;
      } else if (reminder.type === "CONTINUOUS") {
        const repeatIntervalMs = reminder.repeatInterval * 60 * 1000;
        return now.getTime() + repeatIntervalMs - now.getTime();
      }
      return Number.MAX_SAFE_INTEGER;
    },

    async changeReminderActiveStatus(item) {
      let response = unifiedResponseHandler(
          await reminderService.updateReminderStatus(item.id , !item.isActive),
      );
      if (response.success) {
        this.message = response.data;
        this.success = true;
        this.reminders.forEach(reminder => reminder.id === item.id && (reminder.isActive = !reminder.isActive));
      } else {
        this.error = true;
        this.message = response.message || "An error occurred";
      }
    },

    async deleteReminder(item) {
      let response = unifiedResponseHandler(
        await reminderService.deleteReminder(item),
      );
      if (response.success) {
        this.message = response.data;
        this.success = true;
        await this.getReminders();
      } else {
        this.error = true;
        this.message = response.message || "An error occurred";
      }
    },
    calculateRemainingTime(reminder) {
      const now = new Date();
      const alarmDate = new Date(reminder.alarmDate);
      let diffMs;
      if (reminder.type === "SCHEDULED") {
        diffMs = alarmDate - now;
      } else if (reminder.type === "CONTINUOUS") {
        const repeatIntervalMs = reminder.repeatInterval * 60 * 1000;
        const nextInterval = now.getTime() + repeatIntervalMs;
        diffMs = nextInterval - now;
      } else {
        return "N/A";
      }
      if (diffMs <= 0) return "Time's up!";
      const timeUnits = {
        years: Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365)),
        months: Math.floor((diffMs % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30)),
        days: Math.floor((diffMs % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)),
      };
      // eslint-disable-next-line no-unused-vars
      const nonZeroUnits = Object.entries(timeUnits).filter(([_, value]) => value > 0);
      const result = nonZeroUnits.slice(0, 2).map(([unit, value]) => `${value} ${unit}`).join(" and ");
      return result || "Less than a minute";
    },
  },
  computed: {
    formattedReminders() {
      return this.reminders
          .map(reminder => ({
            ...reminder,
            remainingTime: this.calculateRemainingTime(reminder),
            remainingTimeMs: this.getRemainingTimeInMs(reminder)
          }))
          .sort((a, b) => {
            if (a.isActive !== b.isActive) {
              return a.isActive ? -1 : 1;
            }
            return a.remainingTimeMs - b.remainingTimeMs;
          });
    }
  },
  async mounted() {
    await this.getReminders();
    emitter.on("successful-reminder-operation", () => {
      this.getReminders();
    });
    emitter.on("edit-reminder", (value) => {
      this.reminderDetailsModal = false;
      this.editReminder(value);
    });
    emitter.on("delete-reminder", (value) => {
      this.deleteReminder(value);
    });
  },
};
</script>
