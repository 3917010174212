export function authHeader() {
  let user = JSON.parse(localStorage.getItem("auth/user"));

  if (user) {
    return {
      Authorization: "Bearer " + user.jwtToken,
    };
  } else {
    return {};
  }
}

export function authFormDataHeader() {
  let user = JSON.parse(localStorage.getItem("auth/user"));
  if (user) {
    return {
      Authorization: "Bearer " + user.jwtToken,
      "Content-Type": "multipart/form-data",
    };
  } else {
    return {};
  }
}

export function authEmail() {
  try {
    const user = JSON.parse(localStorage.getItem("auth/user"));
    return user?.email || "";
  } catch (error) {
    console.error("Failed to parse user data:", error);
    return "";
  }
}

export function authRole() {
  try {
    const user = JSON.parse(localStorage.getItem("auth/user"));
    return user?.role || {};
  } catch (error) {
    console.error("Failed to parse user data:", error);
    return {};
  }
}
