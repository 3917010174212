<template>
  <div>
    <v-container v-if="!broken_page" fluid style="background-color: white">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
              label="Search"
              v-model="searchValue"
          ></v-text-field>
        </v-col>
      </v-row>
      <download-excel :data="transactionTypesDetails">
        <v-icon>mdi-export</v-icon>
      </download-excel
      >
      <EasyDataTable
          class="datatable-container"
          :headers="headers"
          :items="transactionTypesDetails"
          :search-field="searchField"
          :search-value="searchValue"
          alternating
          border-cell
      >
        <template #item-choose="item">
          <v-icon @click="chooseTransactionType(item)">mdi-check-circle</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>he page is broken</div>

    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>


  </div>
</template>
<script>
import {unifiedResponseHandler} from "@/utils/helpers";
import transactionService from "@/services/transaction.service";
import emitter from "@/plugins/mitt";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";

export default {
  name: "transaction_type_list_v01",
  components: {Success_Modal, Error_Modal},
  data() {
    return {
      headers: [
        {text: "Description", value: "description"},
        {text: "Ledger Direction", value: "ledgerDirection"},
        {text: "Account Direction", value: "accountDirection"},
        {text: "Ledger Code", value: "ledgerCode"},
        {text: "Choose", value: "choose"},
      ],
      searchField: "",
      searchValue: "",
      message: null,
      broken_page: false,
      success: false,
      error: false,
      transactionTypes: [],
    };
  },

  methods: {
    async getTransactionTypes() {
      let response = unifiedResponseHandler(
          await transactionService.getTransactionTypes()
      );
      if (response.success) {
        this.transactionTypes = response.data.sort((a, b) => {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
          return 0;
        });
      } else {
        this.broken_page = true;
      }
    },
    chooseTransactionType(new_transaction_type) {
      emitter.emit("new_transaction_type", new_transaction_type);
    },
  },

  async mounted() {
    await this.getTransactionTypes();
  },
  computed: {
    transactionTypesDetails() {
      return this.transactionTypes.map((transactionType) => ({
        id: transactionType.id,
        description: transactionType.description,
        // direction: transactionType.direction,
        // generalLedger: transactionType.generalLedger,
        // ledgerLabel: transactionType.generalLedger.label,
        ledgerDirection: transactionType.ledgerDirection,
        ledgerCode: transactionType.generalLedgerPrefix,
        //ledgerLabel: transactionType.generalLedger.label,
        //ledgerId: transactionType.generalLedger.id,
        generalLedger: transactionType.generalLedger,
        accountDirection: transactionType.accountDirection || '',
      }));
    },
  },
};
</script>
