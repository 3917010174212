import axios from "axios";
import {authHeader} from "./auth-header";
import {BASE_URL} from "@/config/endpoints";

class ProductService {

    async getAllProducts() {
        try {
            return await axios.get(BASE_URL + "product/getAll", {
                headers: authHeader(),
            });

        } catch (error) {
            return {status: 500, data: null, message: error.response.data};
        }
    }

    async getProductById(id) {
        try {
            return await axios.get(BASE_URL + "product?Id=" + id, {
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.response.data};
        }
    }

    async addProduct(product) {
        try {
            return await axios.post(BASE_URL + "product", product ,{
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.response.data};
        }
    }

    async removeProduct(id) {
        try {
            return await axios.delete(BASE_URL + "product?Id=" + id, {
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.response.data};
        }
    }

    async editProduct(editedProduct) {
        try {
            return await axios.post(BASE_URL + "product/edit", editedProduct ,{
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.response.data};
        }
    }
}

export default new ProductService();
