<template>
  <div>
    <v-container fluid>
      <h5>Route Analysis:</h5>
      <v-form class="mt-4" ref="analysis_form">
        <v-row class="mt-4">
          <v-col md="4" sm="12" xm="12">
            <v-tab
                prepend-icon="mdi-magnify"
                color="button_color"
                @click="sourceAccountListModal = true"
            >select
            </v-tab
            >
            <v-text-field
                @keyup.backspace="clearSourceAccount"
                @keydown="handleKeyDown"
                v-model="sourceAccountName"
                label="Trustee Account"
                :rules="requiredRule"
            ></v-text-field>
          </v-col>

          <v-col md="8" sm="12" xm="12" class="mt-25px">
            <v-text-field
                label="Description"
                v-model="description">

            </v-text-field>
          </v-col>

          <v-col md="4" sm="12" xm="12" style="margin-top:-20px;">
            <v-text-field
                v-maska:[moneyOption]
                v-model="amount"
                label="Original Amount"
                :rules="requiredRule"
            >
            </v-text-field>
            <v-text-field
                v-maska:[percentOption]
                label="Percentage Commission For Original Amount"
                v-model="commissionForOriginalPercentage"
            ></v-text-field>
            <v-text-field
                v-maska:[moneyOption]
                label="Amount Commission For Original Amount"
                v-model="commissionForOriginalAmount"
            ></v-text-field>
            <v-text-field
                v-maska:[exchangeRateOption]
                :label="$t('exchange rate')"
                v-model="exchangeRate"
                :rules="requiredRule"
            ></v-text-field>
            <!-- <v-autocomplete
                label="Select Currency"
                v-model="selectedCurrency"
                :items="currencies"
                item-title="name"
                item-value="name"
                filled
                return-object
                :rules="requiredRule"
                ></v-autocomplete> -->

          </v-col>


          <v-col md="4" sm="12" xm="12" style="margin-top: -20px;">
            <v-text-field
                v-maska:[moneyOption]
                v-model="amountFirstOperation"
                label="Amount First Operation"
                :rules="requiredRule"
            >
            </v-text-field>
            <v-text-field
                v-maska:[percentOption]
                label="Percentage Commission For Second Amount"
                v-model="commissionForSecondPercentage"
            ></v-text-field>
            <v-text-field
                label="Amount Commission For Second Amount"
                v-model="commissionForSecondAmount"
            ></v-text-field>
            <v-text-field
                v-maska:[exchangeRateOption]
                :label="$t('Second Exchange Rate')"
                v-model="secondExchangeRate"
                :rules="requiredRule"
            ></v-text-field>
            <v-autocomplete
                label="Select Second Currency"
                v-model="selectedSecondCurrency"
                :items="currencies"
                item-title="name"
                item-value="name"
                filled
                return-object
                :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col md="4" sm="12" xm="12" style="margin-top: -20px;">
            <v-text-field
                v-model="amountSecondOperation"
                label="Amount Second Operation"
                :rules="requiredRule"
            >
            </v-text-field>
            <v-text-field
                v-maska:[percentOption]
                label="Percentage Commission For Third Amount"
                v-model="commissionForThirdPercentage"
            ></v-text-field>
            <v-text-field
                label="Amount Commission For Third Amount"
                v-model="commissionForThirdAmount"
            ></v-text-field>
            <v-text-field
                v-maska:[exchangeRateOption]
                :label="$t('Third Exchange Rate')"
                v-model="thirdExchangeRate"
                :rules="requiredRule"
            ></v-text-field>
            <v-autocomplete
                label="Select Third Currency"
                v-model="selectedThirdCurrency"
                :items="currencies"
                item-title="name"
                item-value="name"
                filled
                return-object
                :rules="requiredRule"
            ></v-autocomplete>

          </v-col>
        </v-row>
      </v-form>
      <v-row justify="end" class="mt-4">
        <v-btn color="button_color" @click="addCount"
        >Count
        </v-btn>
      </v-row>
      <v-divider></v-divider>
      <v-table height="300px">
        <thead>
        <tr>
          <th class="text-left">Trustee A.</th>
          <th class="text-left">Original A.</th>
          <th class="text-left">Original Com %</th>
          <th class="text-left">Original Com A.</th>
          <th class="text-left">EXR</th>
          <th class="text-left">First A.</th>
          <th class="text-left">Second Com %</th>
          <th class="text-left">Second Com A.</th>
          <th class="text-left">Second EXR</th>
          <th class="text-left">Second A.</th>
          <th class="text-left">Third Com %</th>
          <th class="text-left">Third Com A.</th>
          <th class="text-left">Third EXR</th>
          <th class="text-left">Final A.</th>
        </tr>
        </thead>
        <!-- <tbody>
          <tr>
            <td>{{ this.sourceAccountName }}</td>
            <td>{{ formatNumber(this.amount) }}</td>
            <td>{{ this.exchangeRate }}</td>
            <td>{{ this.commissionForOriginalPercentage }}</td>
            <td>{{ formatNumber(this.amount)}}</td>
            <td>{{ this.commissionForExchangePercentage }}</td>
            <td>{{  }}</td>
          </tr>
        </tbody> -->
      </v-table>
      <v-dialog v-model="sourceAccountListModal" width="auto">
        <list_of_accounts_modal_v01
            third-party-type="TRUSTEE"
        ></list_of_accounts_modal_v01>
      </v-dialog>

    </v-container>
  </div>
</template>
<script>
import emitter from "@/plugins/mitt";
import {requiredRule} from "@/utils/rules";
import list_of_accounts_modal_v01 from "../Modals/List_Of_Accounts_Modal_V01.vue";
import {extractNumber, formatCurrencyInfo, handleKeyDown, unifiedResponseHandler} from "@/utils/helpers";
import {ExchangeRateOption, MoneyOption, PercentageOption} from "@/utils/maska_options";
import CurrencyService from "@/services/currency.service";

export default {
  components: {
    list_of_accounts_modal_v01,
  },
  data() {
    return {
      sourceAccountListModal: false,
      requiredRule: requiredRule,
      amount: null,
      amountFirstOperation: null,
      amountSecondOperation: null,
      targetAmount: null,
      sourceAccount: null,
      commissionForOriginalPercentage: null,
      commissionForOriginalAmount: null,
      commissionForSecondPercentage: null,
      commissionForThirdPercentage: null,
      commissionForSecondAmount: null,
      commissionForThirdAmount: null,
      commissionForExchangePercentage: null,
      percentOption: PercentageOption,
      exchangeRateOption: ExchangeRateOption,
      exchangeRate: null,
      secondExchangeRate: null,
      thirdExchangeRate: null,
      // selectedCurrency: null,
      selectedSecondCurrency: null,
      selectedThirdCurrency: null,
      description: null,
      currencies: [],
    }
  },
  methods: {
    async getCurrencies() {
      let response = unifiedResponseHandler(await CurrencyService.getCurrencies());
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearSourceAccount() {
      this.sourceAccount = null;
    },
    async addCount() {
      let {valid} = await this.$refs.analysis_form.validate();
      if (valid) {
        if (valid) {
          let form = {
            amount: extractNumber(this.amount),
            amountFirstOperation: extractNumber(this.amountFirstOperation),
            amountSecondOperation: extractNumber(this.amountSecondOperation),
            description: this.description,
            sourceAccountId: this.sourceAccountId,
            commissionForOriginalPercentage: extractNumber(this.commissionForOriginalPercentage),
            commissionForOriginalAmount: extractNumber(this.commissionForOriginalAmount),
            commissionForSecondPercentage: extractNumber(this.commissionForSecondPercentage),
            commissionForSecondAmount: extractNumber(this.commissionForSecondAmount),
            commissionForThirdPercentage: extractNumber(this.commissionForThirdPercentage),
            commissionForThirdAmount: extractNumber(this.commissionForThirdAmount),
            exchangeRate: extractNumber(this.exchangeRate),
            secondExchangeRate: extractNumber(this.secondExchangeRate),
            thirdExchangeRate: extractNumber(this.thirdExchangeRate),
            currencyId: this.sourceAccount.currency.id,
            secondCurrencyId: this.selectedSecondCurrency.id,
            selectedThirdCurrency: this.selectedThirdCurrency.id,

          };
          console.log("Hello!", form);
        }
      }
    },
  },
  async mounted() {
    emitter.on("chosenAccount", (value) => {
      this.sourceAccount = value;
      this.sourceAccountListModal = false;
    });
    await this.getCurrencies();
  },
  computed: {
    moneyOption() {
      if (this.sourceAccount) {
        const option = MoneyOption(this.sourceAccount.currency.symbol);
        return option;
      } else {
        return MoneyOption(null);
      }
    },
    sourceAccountId() {
      if (this.sourceAccount) {
        return this.sourceAccount.id;
      } else {
        return null;
      }
    },
    sourceAccountName() {
      if (this.sourceAccount) {
        if (this.sourceAccount.cofferName) {
          return (
              this.sourceAccount.thirdParty.fullName +
              "  " +
              this.sourceAccount.cofferName +
              " " +
              this.sourceAccount.currency.symbol
          );
        } else {
          return (
              this.sourceAccount.thirdParty.fullName +
              "  " +
              this.sourceAccount.currency.symbol
          );
        }
      } else {
        return null;
      }
    },

  },

}
</script>