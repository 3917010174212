<script>
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import {unifiedResponseHandler} from "@/utils/helpers.js";
import userService from "@/services/user.service.js";
import Users_list_modal from "@/components/Modals/User_List_Modal_V01.vue";
import emitter from "@/plugins/mitt.js";
import {requiredRule} from "@/utils/rules.js";

export default {
  name: "permitUserAccessToGroupModal",
  components: { Users_list_modal, Error_Modal, Success_Modal},
  props: ['thirdPartyGroup'],
  data() {
    return {
      loading: true,
      response: null,
      message: '',
      success: false,
      error : false,
      chosenUser: null,
      enteredUserId: "",
      userListModal: false,
    }
  },

  methods: {
    requiredRule() {
      return requiredRule
    },
    async permitUserAccessToGroup() {
      if (this.enteredUserId === "" || this.enteredUserId === null) {
        this.message = "Please enter user id";
        this.error = true
        return;
      }
      if (await this.$refs.new_user_permission_form.validate()) {
        let response = unifiedResponseHandler(await userService.permitUserAccessToThirdPartyGroup(this.enteredUserId, this.thirdPartyGroup.id))
        this.message = response.message
        if (response.success) {
          this.loading = false;
          this.success = true;
          emitter.emit("successPermitUserOperation", response.data);
        } else {
          this.error = true;
        }
        this.chosenUser = null;
        this.enteredUserId = "";
      }
    },
  },

  async mounted() {
    emitter.on("new_user", (value) => {
      if (this.userListModal) {
        this.chosenUser = value;
        this.enteredUserId = this.chosenUser.id
        this.userListModal = false;
      }
    });
  },
  computed: {
    selectedUserFirstName() {
      return this.chosenUser?.firstName || null
    }
  }
}
</script>

<template>
  <v-container fluid style="background-color: white; width: 450px; height: 250px; border-radius: 5px;">
  <v-form ref = "new_user_permission_form" class="mt-4">
    <v-row>
      <v-col cols="12" md="12" sm="12" xs="12">
        <v-tab
            @click="userListModal = true"
            prepend-icon="mdi-magnify"
            color="button_color"
        >select
        </v-tab>
        <v-text-field
            v-model="selectedUserFirstName"
            label="Selected User First Name"
            :rules="requiredRule()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="mt-0 mb-0" cols="auto">
        <v-btn class="mr-4" color="button_color"
               @click="permitUserAccessToGroup">
          Permit User Access To Group
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</v-container>
  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>
  <v-dialog v-model="userListModal" width="auto">
    <Users_list_modal/>
  </v-dialog>
</template>

<style scoped>

</style>