<script>
export default {
  name: "NewFeaturesModal.vue",
  props: {
    features: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeFeaturesModal");
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="400"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        <span>🚀 What's New</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="(feature, index) in features.slice(0, 10)"
            :key="index"
          >
            {{ feature }}
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn color="button_color" block @click="closeModal">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
