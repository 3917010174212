<template>
    <div>
      <v-container fluid style="background-color: white; width: 500px; height: 450px; border-radius: 5px;">
        <v-form  ref="form">
          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12">
                <v-text-field
                    v-model="currencyName"
                    :rules="requiredRule"
                    label="Currency Name:"
                    required
                    ></v-text-field>
                    <v-text-field
                    v-model="currencySymbol"
                    :rules="symbolValidationRule"
                    label="Symbol:"
                    required
                    >
                </v-text-field>
                    <v-text-field
                    v-model="currencyCode"
                    :rules="isTwoDigitNumber"
                    label="Currency Code:"
                    required
                    >
                </v-text-field>
                <v-text-field
                    v-model="xeequivalent"
                    label="Equivalent: (must have 3 characters)"
                    >
                </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="end">
            <div class="d-flex top-20 bottom-20 right-20">
              <v-btn
              v-if="!is_editing_currency"
                class="ml-4"
                color="button_color"
                @click="addCurrency"
              >
                Add Currency
              </v-btn>
              <v-btn
              v-else
              class="ml-4"
              color="button_color"
              @click="addCurrency"
            >
              Edit Currency
            </v-btn>
            </div>
          </v-row>
        </v-form>
        <!-- <v-dialog v-model="success" width="auto">
          <v-alert type="success"> {{ message }} </v-alert>
        </v-dialog>
        <v-dialog v-model="error" width="auto">
          <v-alert type="error"> {{ message }} </v-alert>
        </v-dialog> -->
        <v-dialog v-model="success">
        <div class="success-dialog">
          <div class="success-left">
            <div class="green-circle">
              <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 100 L80 130 L150 50" stroke="white" stroke-width="25" fill="transparent" class="success-tick" />
          </svg>
        </div>
          </div>
          <div class="success-right">
          <span  @click="success = false" style="text-align: right;">✖</span>
          <h4>Success</h4>
        <p class="success-message"> {{ message }}</p>
        <button class="success-close-button" @click="success = false">Close</button>
          </div>
        </div>
    </v-dialog>
    
    <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>      
      </div>
      <div class="error-right">
      <span  @click="error = false" style="text-align: right;">✖</span>
      <h4>Error</h4>
    <p class="error-message">{{ message }}</p>
    <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
</v-dialog>
      </v-container>
    </div>
  </template>
  <script>
  import {requiredRule,isTwoDigitNumber,symbolValidationRule } from "@/utils/rules.js";
  import {unifiedResponseHandler } from "@/utils/helpers";
  import currencyService from "@/services/currency.service";
  import emitter from "@/plugins/mitt";
  import { mapGetters } from "vuex";

  export default {
    name: "new_currency_modal_v01",
    data() {
      return {
        error: false,
        success: false,
        message: null,
        currencyName: null,
        currencySymbol: null,
        currencyCode: null,
        xeequivalent: null,
        id: null,
        requiredRule: requiredRule,
        symbolValidationRule: symbolValidationRule,
        isTwoDigitNumber: isTwoDigitNumber,
      };
    },
    methods:{
      async addCurrency(){
        const { valid } = await this.$refs.form.validate();
        if (valid) {
          let form = {
            id:this.id,
            name: this.currencyName,
            symbol: this.currencySymbol,
            currencyCode:this.currencyCode,
            xeequivalent:this.xeequivalent,
          };
          let response = null;
        if (!this.is_editing_currency) {
          response = unifiedResponseHandler(
             await currencyService.addCurrency(form)
          );
        } else {
          response = unifiedResponseHandler(
            await currencyService.editCurrency(form)
          );
        }
          if (response.success) {
            this.success = true;
            this.message = response.data;
            emitter.emit("new_currency", response);
          } else {
            this.message = response.message;
            this.error = true;
          }
      }
    },
  },     async mounted() {   
        if(this.is_editing_currency){
          this.id=this.chosen_currency.id;
          this.currencyCode= this.chosen_currency.currencyCode;
          this.currencySymbol= this.chosen_currency.symbol;
          this.currencyName= this.chosen_currency.name;
          this.xeequivalent= this.chosen_currency.xeequivalent;
          console.log(this.chosen_currency)
        }
      },
   computed: {
    ...mapGetters([
      "chosen_currency",
      "is_editing_currency",
    ]),},
  beforeUnmount() {
    emitter.emit("new_currency", null);
    this.$store.dispatch("setChosenCurrency", null);
    this.$store.dispatch("setIsEditingCurrency", false);
  },}
  </script>
  