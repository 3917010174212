import axios from "axios";
import {authHeader} from "./auth-header";
import {BASE_URL} from "@/config/endpoints";

class ThirdPartyGroupService {

    async getThirdPartyGroups() {
        try {
            return await axios.get(BASE_URL + "thirdPartyGroup/getAll", {
                headers: authHeader(),
            });

        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getThirdPartyGroupById(id) {
        try {
            return await axios.get(BASE_URL + "thirdPartyGroup?groupId=" + id, {
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async addThirdPartyGroup(third_party_group) {
        try {
            return await axios.post(BASE_URL + "thirdPartyGroup", third_party_group ,{
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async editThirdPartyGroup(third_party_group) {
        try {
            return await axios.post(BASE_URL + "thirdPartyGroup/edit", third_party_group ,{
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async removeThirdPartyGroup(id) {
        try {
            return await axios.delete(BASE_URL + "thirdPartyGroup?groupId=" + id, {
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getAllThirdPartiesInGroup(id) {
        try {
            return await axios.get(BASE_URL + "thirdPartyGroup/thirdParty?groupId=" + id, {
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async removeThirdPartyFromGroup(groupId, thirdPartyId) {
        try {
            return await axios.delete(BASE_URL + "thirdPartyGroup/thirdParty?groupId=" + groupId+ "&thirdPartyId=" + thirdPartyId, {
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async addThirdPartyToGroup(groupId, thirdPartyId) {
        try {
            return await axios.post(BASE_URL + "thirdPartyGroup/thirdParty?groupId=" + groupId+ "&thirdPartyId=" + thirdPartyId, {}, {
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getPermittedUsers(groupId) {
        try {
            return await axios.get(BASE_URL + "thirdPartyGroup/users?groupId=" + groupId , {
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }
}

export default new ThirdPartyGroupService();
