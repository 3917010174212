export const ACCEPTED_FILE_TYPES = ".pdf,image/*,.xls,.txt,.doc,.docx";

export const MONEY_TRANSFER_TABS = [
    {
        title: "NOT INITIATED",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "INITIATED",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "WAITING FOR APPROVAL",
        iconSuccess: null,
        isValid: true,
    },

    {
        title: "APPROVED",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "TRANSACTION REQUESTED",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "TRUSTEE CONFIRMED",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "COMPLETED",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "ACCOUNTS UPDATED",
        iconSuccess: null,
        isValid: true,
    },
];

export const INTERNAL_TRANSFER_TABS = [
    {
        title: "NOT INITIATED",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "INITIATED",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "WAITING FOR APPROVAL",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "ADMIN APPROVED",
        iconSuccess: null,
        isValid: true,
    },

    {
        title: "TRANSACTION REQ",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "FIRST PARTY CONFIRMED",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "COMPLETED",
        iconSuccess: null,
        isValid: true,
    },
    {
        title: "ACCOUNTS UPDATED",
        iconSuccess: null,
        isValid: true,
    },
];
export const VERSION_FEATURES = [

    {
        version: "1.0",
        date: "2024-9-17",
        features: ["Added a modal for displaying new features, YAY!"],
    },
    {
        version: "1.1",
        date: "2024-9-17",
        features: ["Now you can get SOA report without currency"],
    },
    {
        version: "1.2",
        date: "2024-9-18",
        features: ["Updating coffer name of account is now possible.",
        "Fixed Side Bar issue.",
        "Updated display of each third-party type third-parties list.",
        "By adding a new third-party it'll be automatically added to default third-party-group.",
        "You can now search for a crypto deal by id of it, buy, receive or send amount."],
    },
    {
        version: "1.3",
        date: "2024-9-22",
        features: ["Fixed search bars and made the look of them consistent.",
        "Now the calculation for crypto deal exchange rate would be done automatically.",
        "Fixed the bug where trustee accounts would not be created or updated.",
        "Fixed some bugs with sidebar."],
    },
    {
        version: "1.4",
        date: "2024-9-29",
        features: ["Added reconciliation for crypto deal.",
        "User experience improvements in crypto deal form."],
    },
    {
        version: "1.5",
        date: "2024-10-3",
        features: ["Added Delete for crypto deal."],
    },
    {
        version: "1.6",
        date: "2024-10-5",
        features: ["New third party types : Income Center & Cost Center",
        "Deletion of crypto deal.",
        "Automatic creation of general ledgers.",
        "Other improvements."],
    },
    {
        version: "1.7",
        date: "2024-10-19",
        features: ["New Features : Meeting & Reminder Systems"],
    }

]
export const PAGE_SIZE = 25;
