import axios from "axios";
import {authFormDataHeader, authHeader} from "./auth-header";
import {BASE_URL} from "@/config/endpoints";

class UserService {
  async addUser(user) {
    try {
      return await axios.post(BASE_URL + "user/addUser", user, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async editUser(user) {
    try {
      return await axios.post(BASE_URL + "user/editUser", user, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async getUsers() {
    try {
      return await axios.get(BASE_URL + "user/users", {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async changePassword(chang_password_form) {
    try {
      return await axios.post(
        BASE_URL + "user/changePassword",
        chang_password_form,
        { headers: authHeader() }
      );
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async enableUser(email) {
    try {
      return await axios.post(
        BASE_URL + "user/enable",
        { email: email },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async attachFiles(email, file) {
    try {
      return await axios.post(
        BASE_URL + "user/uploadAttachment",
        { userEmail: email, attachment: file },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async disableUser(email) {
    try {
      return await axios.post(
        BASE_URL + "user/disable",
        { email: email },
        {
          headers: authFormDataHeader(),
        }
      );
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async deleteUser(email) {
    try {
      return await axios.delete(BASE_URL + "user/delete", {
        headers: authHeader(),
        params: { email: email },
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async getUserRole(email) {
    try {
      return await axios.get(BASE_URL + "user/role", {
        headers: authFormDataHeader(),
        params: {
          email: email,
        },
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async setNewRules(change_roles_form) {
    try {
      return await axios.post(
        BASE_URL + "user/updateUserRoles",
        change_roles_form,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async getUserFiles(email) {
    try {
      return await axios.get(BASE_URL + "user/attachments?email=" + email, {
        responseType: "json", // Specify the response type as 'json'
        headers: authHeader(),
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async permitUserAccessToThirdPartyGroup(userId, thirdPartyGroupId) {
    try {
      return await axios.post(BASE_URL + "user/thirdPartyGroup", { } , {
        headers: authHeader(),
        params: {
          userId: userId,
          groupId: thirdPartyGroupId
        },
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }


  async removeUserAccessToThirdPartyGroup(userId, thirdPartyGroupId) {
    try {
      return await axios.delete(BASE_URL + "user/thirdPartyGroup", {
        headers: authHeader(),
        params: {
          userId: userId,
          groupId: thirdPartyGroupId
        },
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }
}

export default new UserService();
