<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium mb-2">Safe Box Report</h3>
      <v-row class="mb-2">
        <v-col cols="12" sm="4">
          <div class="d-flex">
            <v-text-field
                class="search-field"
                label="Search"
                v-model="searchValue"
                @keyup.enter="searchForItem(searchValue)"
            >
              <template v-slot:prepend-inner>
                <v-icon
                    icon="mdi-magnify"
                    variant="tonal"
                    color="button_color"
                    class="mr-3"
                >
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
              color="button_color"
              @click="QMoneyTransfersModal = true"
              icon="mdi-help"
              class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="safeBoxDetails">
              <v-icon>mdi-export</v-icon>
            </download-excel>
          </v-btn>
        </v-col>
      </v-row>

      <EasyDataTable
          style=""
          :headers="headers"
          :items="tableItems"
          :search-field="searchField"
          :search-value="searchValue"
          body-text-direction="center"
          header-text-direction="center"
          alternating
          border-cell
          class="custom-data-table"
          :loading="loading"
          :body-row-class-name="bodyRowClassNameFunction"
          no-hover
      >
        <template #pagination="{}">
          <v-btn
              density="compact"
              class="ma-4"
              :disabled="serverOptions.page === 1"
              @click="goToPrevPage"
          >prev page
          </v-btn>
          <v-btn
              density="compact"
              class="ma-4"
              :disabled="serverOptions.page === numberOfPages"
              @click="goToNextPage"
          >next page
          </v-btn>
        </template>

        <template #item-id="item">
          <v-icon @click="gotToRequest(item)"
          ><span class="modal-icon">{{ item.id }}</span></v-icon
          >
        </template>

        <template #item-amount="{ withdrawalAmount }">
          <div class="currency-amount">
            <span class="currency">{{
                splitCurrencyValue(withdrawalAmount).currency
              }}</span>
            <span class="text-right">{{
                splitCurrencyValue(withdrawalAmount).value
              }}</span>
          </div>
        </template>
        <template #item-update="item">
          <Toggle_Confirmation
              @acceptTransfer="acceptTransfer(item)"
              @denyTransfer="denyTransfer(item)"
              :item="item"
              :action="action"
              :editable="false"
          >
          </Toggle_Confirmation>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="SafeBoxModal" width="auto">
      <SafeBox_Modal_V01></SafeBox_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import safeBoxOperationService from "@/services/SafeBoxOperationService";
import {
  pageResponseHandler,
  unifiedResponseHandler,
  formatNumber,
} from "@/utils/helpers";
import SafeBox_Modal_V01 from "../../Modals/transfer/SafeBox_Modal_V01.vue";
import Toggle_Confirmation from "@/components/MoneyTransfer/Toggle_Confirmation.vue";
import getStatusDisplay from "@/utils/NewFunctions/getStatusDisplay";

export default {
  name: "safebox_report_v01",
  components: {
    Toggle_Confirmation,
    Broken_Page_V01,
    Error_Modal,
    Success_Modal,
    SafeBox_Modal_V01,
  },
  data() {
    return {
      headers: [
        {text: "Event", value: "id", width: 30},
        {text: "Coffer Name", value: "safeBoxCofferName"},
        {text: "Deposit Amt", value: "depositAmount"},
        {text: "Withdrawal Amt", value: "withdrawalAmount"},
        {text: "Payer/Payee", value: "payerPayeeFullName"},
        {text: "Create Date", value: "createDate"},
        {text: "Modified Date", value: "modifiedDate"},
        {text: "User", value: "createdBy"},
        {text: "Status", value: "status"},
        {text: "Update", value: "update"},
      ],
      searchField: "",
      searchValue: "",
      message: null,
      broken_page: false,
      success: false,
      error: false,
      tableItems: [],
      action: false,
      currentPage: 0,
      totalItems: 0,
      requests: [],
      accountCofferName: null,
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
      },
      loading: false,
      requestModal: false,
      statusCriteria: "ALL",
      showStatusFilter: false,
      SafeBoxModal: false,
    };
  },

  methods: {
    bodyRowClassNameFunction(item) {
      switch (item.status) {
        case "Completed":
          return "row-completed";
        case "Canceled":
          return "row-canceled";
        case "Approved":
          return "row-approved";
        default:
          return "";
      }
    },
    splitCurrencyValue(currencyString) {
      const parts = currencyString.split(" ");
      return {
        currency: parts[0],
        value: parts[1],
      };
    },
    goToPrevPage() {
      this.serverOptions.page = this.serverOptions.page - 1;
    },
    goToNextPage() {
      this.serverOptions.page = this.serverOptions.page + 1;
    },
    searchForItem(item) {
      this.accountCofferName = item;
      if (this.serverOptions.page === 1) {
        this.getRequests();
      } else {
        this.serverOptions.page = 1;
      }
    },
    async getRequests() {
      let response;
      this.loading = true;
      response = pageResponseHandler(
          await safeBoxOperationService.getAllSafeBoxOperation(
              this.serverOptions.page - 1,
              this.serverOptions.rowsPerPage,
              this.accountCofferName
          )
      );
      console.log("response", response);
      if (response.success) {
        this.totalItems = response.totalItems;
        this.numberOfPages = response.numberOfPages;
        this.requests = response.data;
        this.tableItems = this.safeBoxDetails;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    async gotToRequest(item) {
      let response = unifiedResponseHandler(
          await safeBoxOperationService.getSafeBoxOperationById(item.id)
      );
      if (response.success) {
        let requestToSet = response.data;
        this.$store.dispatch("setChosenSafeBox", requestToSet);
        this.SafeBoxModal = true;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async acceptTransfer(item) {
      this.action = true;
      let response = unifiedResponseHandler(
          await safeBoxOperationService.requestForAccountUpdate(item.id)
      );
      this.action = false;
      if (response.success) {
        item.status = "Acc Updated";
        let index = this.tableItems.findIndex(
            (lookingForItem) => lookingForItem.id === item.id
        );
        this.tableItems[index] = item;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async denyTransfer(item) {
      this.action = true;
      let response = unifiedResponseHandler(
          await safeBoxOperationService.cancelSafeBoxOperation(item.id)
      );
      this.action = false;
      if (response.success) {
        item.status = "Canceled";
        let index = this.tableItems.findIndex(
            (lookingForItem) => lookingForItem.id === item.id
        );
        this.tableItems[index] = item;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },

  async mounted() {
    await this.getRequests();
  },
  computed: {
    safeBoxDetails() {
      return this.requests.map((request) => {
        let transactionStatus = getStatusDisplay(request.status);
        return {
          id: request.id,
          safeBoxCofferName: request.safeBoxCofferName || null,
          payerPayeeFullName: request.payerPayeeFullName || null,
          safeBoxThirdPartyFullName: request.safeBoxThirdPartyFullName || null,
          withdrawalAmount: request.currencySymbol + " " + formatNumber(request.withdrawalAmount) || null,
          depositAmount: request.currencySymbol + " " + formatNumber(request.depositAmount) || null,
          createDate: new Date(request.createDate).toISOString().split("T")[0] || null,
          modifiedDate: new Date(request.modifiedDate).toISOString().split("T")[0] || null,
          createdBy: request.createdBy || null,
          status: transactionStatus,
        };
      });
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.serverOptions.rowsPerPage);
    },
  },
  watch: {
    searchValue(newVal) {
      if (newVal === "") {
        clearTimeout(this.debounceTimer);
        this.serverOptions.page = 1;
        this.debounceTimer = setTimeout(() => {
          this.getRequests();
        }, 500);
      } else {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.searchForItem(newVal);
        }, 500);
      }
    },
    serverOptions: {
      handler() {
        if (this.searchValue !== null && this.searchValue !== "")
          this.searchForItem(this.searchValue)
        else
          this.getRequests();
      },
      deep: true,
    },
  },
};
</script>
<style>
.two-thirds-width {
  flex: 0 0 66.67%; /* flex-basis set to 2/3 of the container */
}
</style>
