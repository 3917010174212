<template>
  <div>
    <v-container fluid style="background-color: white; width: 600px">
      <v-form ref="add_account_type_form">
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-autocomplete
                label="Select Event Type"
                v-model="eventType"
                :items="eventTypes"
                item-title="name"
                item-value="id"
                filled
                return-object
                :rules="requiredRule"
            ></v-autocomplete>

            <v-autocomplete
                v-if="eventType !== null && selectedEventTypes !== null "
                :label="'Select ' +  eventType.name + ' Type'"
                v-model="selectedEventType"
                :items="selectedEventTypes"
                item-title="name"
                item-value="id"
                filled
                return-object
                :rules="requiredRule"
            ></v-autocomplete>

            <v-autocomplete
                v-if="eventType !== null && eventType.name !== 'Manual Transaction' "
                label="Mnemonic"
                v-model="mnemonic"
                :items="mnemonics"
                item-title="name"
                item-value="id"
                filled
                return-object
                :rules="requiredRule"
            ></v-autocomplete>

            <v-tab
                @click="transactionTypeListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab
            >
            <v-text-field
                label="Transaction Type"
                v-model="transactionTypeName"
                @keyup.backspace="clearTransactionType"
                @keydown="handleKeyDown"
                :rules="requiredRule"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
          <div class="d-flex top-20">
            <v-btn
                v-if="!is_editing_transaction_type_config"
                class="ml-4"
                color="button_color"
                @click="addTransactionTypeConfig"
            >
              Add Transaction Type Config
            </v-btn>
            <v-btn
                v-else
                class="ml-4"
                color="button_color"
                @click="addTransactionTypeConfig"
            >
              Edit Transaction Type Config
            </v-btn>
          </div>
        </v-row>
      </v-form>
      <Success_Modal
          :message="message"
          :success="success"
          @closeSuccessModal="success = false"
      ></Success_Modal>
      <Error_Modal
          :error="error"
          :message="message"
          @closeErrorModal="error = false"
      ></Error_Modal>
      <v-dialog v-model="transactionTypeListModal" width="auto">
        <Transaction_Type_List_Modal_V01></Transaction_Type_List_Modal_V01>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import {handleKeyDown, unifiedResponseHandler,} from "@/utils/helpers";
import {requiredRule} from "@/utils/rules";
import emitter from "@/plugins/mitt";
import transactionService from "@/services/transaction.service";
import Transaction_Type_List_Modal_V01 from "./Transaction_Type_List_Modal_V01.vue";
import {mapGetters} from "vuex";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";

export default {
  name: "new_transaction_type_config_modal_v01",
  components: {
    Error_Modal, Success_Modal,
    Transaction_Type_List_Modal_V01,
  },
  data() {
    return {
      error: false,
      success: false,
      message: null,
      transactionType: null,
      selectedEventType: null,
      selectedInternalType: null,
      selectedManualType: null,
      eventType: null,
      transactionTypeConfigId: null,
      moneyTransferTypes: [
        {name: "Cash Receive", id: 0},
        {name: "Cash Send", id: 1},
        {name: "Bank Receive", id: 2},
        {name: "Bank Send", id: 3},
      ],

      internalTypes: [
        {name: "Customer to Customer", id: 0},
        {name: "Trustee to Trustee", id: 1},
      ],

      manualTypes: [
        {name: "Money / Internal Transfer - Correction", id: 0},
        {name: "Bill Payment - Correction", id: 1},
        {name: "No Event Transaction", id: 2},
      ],

      eventTypes: [
        {id: 0, name: "Money Transfer"},
        {id: 1, name: "Internal Transfer"},
        {id: 3, name: "Manual Transaction"},
        {id: 4, name: "Crypto Deal"},
        {id: 5, name: "Safe Box Operation"},
        {id: 6, name: "Group Deal"}
      ],

      mnemonic: null,

      mnemonicsMoneyTransfer: [
        {name: "Trustee Original Amount: TOOO", symbol: "TOOO"},
        {name: "Customer Original Amount: COOO", symbol: "COOO"},
        {name: "Customer Commission Amount: CCEA", symbol: "CCEA"},
        {name: "Customer Commission Percentage: CCEP", symbol: "CCEP"},
        {name: "Trustee Commission Amount: TCTA", symbol: "TCTA"},
        {name: "Trustee Commission Percentage: TCTP", symbol: "TCTP"},
      ],
      mnemonicManual: null,
      mnemonicsManual: [
        {name: "Trustee Original Amount Debit: TOAD", symbol: "TOAD"},
        {name: "Trustee Original Amount Credit: TOAC", symbol: "TOAC"},
        {name: "Trustee Commission Amount Debit: TCAD", symbol: "TCAD"},
        {name: "Trustee Commission Amount Credit: TCAC", symbol: "TCAC"},
        {name: "Customer Original Amount Debit: COAD", symbol: "COAD"},
        {name: "Customer Original Amount Credit: COAC", symbol: "COAC"},
        {name: "Customer Commission Amount Debit: CCAD", symbol: "CCAD"},
        {name: "Customer Commission Amount Credit: CCAC", symbol: "CCAC"},
      ],
      mnemonicCryptoDeal: null,
      mnemonicsCryptoDeal: [
        {name: "Crypto Deal Receive Our Account: CDRO", symbol: "CDRO"},
        {name: "Crypto Deal Receive Third Party: CDRT", symbol: "CDRT"},
        {name: "Crypto Deal Send Our Account: CDSO", symbol: "CDSO"},
        {name: "Crypto Deal Send Third Party: CDST", symbol: "CDST"},
      ],
      mnemonicGroupDeal: null,
      mnemonicsGroupDeal: [
        {name: "Group Deal Our Receive Account: GDORA", symbol: "GDORA"},
        {name: "Group Deal Our Primary Send Account: GDOPSA", symbol: "GDOPSA"},
        {name: "Group Deal Our Secondary Send Account: GDOSSA", symbol: "GDOSSA"},
        {name: "Group Deal Customer Send Account: GDCSA", symbol: "GDCSA"},
        {name: "Group Deal Primary Customer Receive Account: GDPCRA", symbol: "GDPCRA"},
        {name: "Group Deal Secondary Customer Receive Account: GDSCRA", symbol: "GDSCRA"},
        {name: "Group Deal TET Buy Account: GDTBA", symbol: "GDTBA"},
        {name: "Group Deal TET Sell Account: GDTSA", symbol: "GDTSA"},
      ],
      mnemonicsSafeBoxOperation: [
        {name: "Safe Box Operation Deposit Money: SBD", symbol: "SBD"},
        {name: "Safe Box Operation Withdraw Money: SBW", symbol: "SBW"},
      ],
      description: null,
      requiredRule: requiredRule,
      transactionTypeListModal: false,
    };
  },
  methods: {
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearTransactionType() {
      this.transactionType = null;
    },


    async addTransactionTypeConfig() {
      let {valid} = await this.$refs.add_account_type_form.validate();
      if (valid) {
        let form = {
          eventType: this.eventType.id,
          id: this.transactionTypeConfigId,
          mnemonic: this.mnemonic.symbol,
          transactionTypeId: this.transactionTypeId,
        };
        let switchVar = this.eventType.name
        switch (switchVar) {
          case "Money Transfer":
            form.moneyTransferRequestType = this.selectedEventType.id;
            break;
          case "Internal Transfer":
            form.internalTransferType = this.selectedEventType.id
            break;
          case "Crypto Deal":
            form.cryptoDeal = true;
            break;
          case "Manual Transaction":
            form.manualTransactionType = this.selectedEventType.id
            break;
          case "Safe Box Operation":
            form.safeBoxOperation = true;
            break;
          case "Group Deal":
            form.groupDeal = true;
            break;
        }
        console.log(form);
        let response = null;
        if (!this.is_editing_transaction_type_config) {
          response = unifiedResponseHandler(
              await transactionService.addTransactionTypeConfig(form)
          );
        } else {
          response = unifiedResponseHandler(
              await transactionService.editTransactionTypeConfig(form)
          );
        }

        if (response) {
          this.message = response.message || " ";
          if (response.success) {
            emitter.emit("new_transaction_type_config", response);
          } else {
            this.error = true;
          }
        } else {
          console.error(' ');
        }
      }
    },
    clearThirdParty() {
      this.thirdParty = null;
    },
  },
  async mounted() {
    emitter.on("new_transaction_type", (value) => {
      this.transactionType = value;
      this.transactionTypeListModal = false;
    });
    if (this.is_editing_transaction_type_config) {
      this.transactionTypeConfigId = this.chosen_transaction_type_config.id;
      this.transactionType = this.chosen_transaction_type_config.transactionType;
      this.mnemonic = this.chosen_transaction_type_config.mnemonic;

      const requestType = this.chosen_transaction_type_config.requestType;
      switch (true) {
        case requestType.startsWith('Money Transfer'):
          this.eventType = this.eventTypes.find(event => event.name === "Money Transfer");
          break;
        case requestType.startsWith('Internal Transfer'):
          this.eventType = this.eventTypes.find(event => event.name === "Internal Transfer");
          break;
        case requestType.startsWith('Manual'):
          this.eventType = this.eventTypes.find(event => event.name === "Manual Transaction");
          break;
        case requestType.startsWith('Safe'):
          this.eventType = this.eventTypes.find(event => event.name === "Safe Box Operation");
          break;
        case requestType.startsWith('Crypto'):
          this.eventType = this.eventTypes.find(event => event.name === "Crypto Deal");
          break;
        case requestType.startsWith('Group'):
          this.eventType = this.eventTypes.find(event => event.name === "Group Deal");
          break;
        default:
          this.transactionTypeConfigId = null;
          this.transactionType = null;
          this.mnemonic = null;
          break;
      }
    }
  },
  computed: {
    ...mapGetters([
      "chosen_transaction_type_config",
      "is_editing_transaction_type_config",
    ]),
    mnemonics() {
      let switchVar = this.eventType.name
      switch (switchVar) {
        case "Internal Transfer":
        case "Money Transfer":
          return this.mnemonicsMoneyTransfer
        case "Manual Transaction":
          return this.mnemonicsManual
        case "Crypto Deal":
          return this.mnemonicsCryptoDeal
        case "Safe Box Operation":
          return this.mnemonicsSafeBoxOperation
        case "Group Deal":
          return this.mnemonicsGroupDeal
      }
      return null;
    },
    selectedEventTypes() {
      let switchVar = this.eventType.name
      switch (switchVar) {
        case "Internal Transfer":
          return this.internalTypes
        case "Money Transfer":
          return this.moneyTransferTypes
        case "Manual Transaction":
          return this.manualTypes
      }
      return null;
    },
    transactionTypeName() {
      if (this.transactionType) {
        return (
            this.transactionType.description
        );
      } else {
        return null;
      }
    },
    transactionTypeId() {
      if (this.transactionType) {
        return this.transactionType.id;
      } else {
        return null;
      }
    },

  },
  beforeUnmount() {
    emitter.emit("new_transaction_type", null);
    this.$store.dispatch("setChosenTransactionTypeConfig", null);
    this.$store.dispatch("setIsEditingTransactionTypeConfig", false);
  },
};
</script>
