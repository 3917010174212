<template>
  <v-sheet max-width="800" min-width="600">
    <div style="padding: 20px">
      <div class="border-1">
        <h4 class="border-h4">Money Transfer Summary</h4>
        <div
          class="grid-card-2"
          ref="pdfContent"
          style="text-align: left; direction: ltr"
        >
          <p>Event:</p>
          <p class="m-l-35">{{ chosen_request.id }}</p>
          <p>Customer:</p>
          <p class="m-l-35">
            {{ chosen_request.customerAccount?.cofferName || null }}
          </p>
          <p>Reference No.:</p>
          <p class="m-l-35">{{ chosen_request.referenceNo }}</p>
          <p>Request type:</p>
          <p class="m-l-35">{{ getMoneyTransferRequestType(chosen_request.requestType) }}</p>
          <p>Amount:</p>
          <p class="m-l-35">
            {{ formatNumber(chosen_request.amount) }}
            {{ chosen_request.currency.symbol }}
          </p>
          <p>Payer/Payee:</p>
          <p class="m-l-35">
            {{ chosen_request.secondaryThirdParty?.fullName || "" }}
          </p>
          <p>Trustee Coffer:</p>
          <p class="m-l-35">
            {{ chosen_request.trusteeAccount?.cofferName || null }}
          </p>
          <p>Status:</p>
          <p class="m-l-35">{{ getStatusDisplay(chosen_request.status) }}</p>
          <p>Trustee Com Amt:</p>
          <p class="m-l-35">
            {{ chosen_request.trusteeCommissionAmount || 0 }}
          </p>
          <p>Trustee Com %:</p>
          <p class="m-l-35">
            {{chosen_request.trusteeCommissionPercent || 0 }}%
          </p>
          <p>Customer Com Amt:</p>
          <p class="m-l-35">
            {{chosen_request.ellingtonCommissionAmount || 0 }}
          </p>
          <p>Customer Com %:</p>
          <p class="m-l-35">
            {{ chosen_request.ellingtonCommissionPercent || 0 }}%
          </p>
          <p>Note:</p>
          <p class="m-l-35">{{ chosen_request.description }}</p>
          <p>Creating date:</p>
          <p class="m-l-35">{{ chosen_request.createDate }}</p>
          <p>Modified on:</p>
          <p class="m-l-35">{{ chosen_request.modifiedDate }}</p>
        </div>
        <v-row justify="end">
          <v-btn class="mr-4" color="button_color" @click="generatePDF"
            >PDF</v-btn
          >
          <v-btn
            v-if="chosen_request.status === 'WAITING_FOR_APPROVAL'"
            @click="approveRequest"
            class="mr-4"
            color="button_color"
          >
            {{ $t("confirm") }} request
          </v-btn>
        </v-row>
      </div>
    </div>
  </v-sheet>
  <Error_Modal
    :error="error"
    :message="message"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import {formatNumber, unifiedResponseHandler} from "@/utils/helpers.js";
import money_transfer_requestService from "@/services/money_transfer_request.service.js";
import getMoneyTransferRequestType from "@/utils/NewFunctions/getMoneyTransferRequestType.js";
import getStatusDisplay from "@/utils/NewFunctions/getStatusDisplay.js";
import { mapGetters } from "vuex";
import emitter from "@/plugins/mitt.js";
import html2pdf from "html2pdf.js";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
export default {
  name: "money_transfer_request_modal",
  components: {
    Error_Modal,
  },
  data() {
    return {
      message: null,
      error: false,
    };
  },
  methods: {
    getStatusDisplay,
    formatNumber,
    getMoneyTransferRequestType,
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: "moneyTransferSummary.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf(element, options);
    },
    async approveRequest() {
      let response = unifiedResponseHandler(
        await money_transfer_requestService.changeRequestStatusToApproved(
          this.chosen_request.id
        )
      );
      this.message = response.message;
      if (response.success) {
        emitter.emit("request_modal_close", this.message);
      } else {
        this.error = true;
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["chosen_request", "page"]),
  },
};
</script>
