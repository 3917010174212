import {createApp} from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import mitt from "mitt";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import CountryFlag from "vue-country-flag-next";
import "./assets/css/style.css";
import {vMaska} from "maska";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import JsonExcel from "vue-json-excel3";
const emitter = mitt();


console.log(i18n.global.messages); // This will log the internal messages structure of vue-i18n
const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);
app.use(vuetify);

app.component("EasyDataTable", Vue3EasyDataTable);
app.component("VueDatePicker", VueDatePicker);
app.component("downloadExcel", JsonExcel);
app.component("country-flag", CountryFlag);

app.directive("maska", vMaska);

app.config.globalProperties.emitter = emitter;

app.mount("#app");
