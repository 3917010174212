import axios from "axios";
import { authHeader } from "./auth-header";
import { BASE_URL } from "@/config/endpoints";

class accountService {
  async addAccount(account) {
    try {
      return await axios.post(
        BASE_URL + "account/addAccount",
        account,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async editAccount(editAccount) {
    try {
      return await axios.post(
          BASE_URL + "account/edit",
          editAccount,
          {
            headers: authHeader(),
          }
      );
    } catch (error) {
      return { status: 500, data: null, message: error.response.data };
    }
  }

  async getAllThirdPartiesAccountByThirdPartyType(thirdPartyType,currencyId){
    try {
      return await axios.get(BASE_URL + "account/getAll", {
        headers: authHeader(),
        params: {thirdPartyType : thirdPartyType, currencyId : currencyId},
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async getThirdPartyCurrencyAccounts(currencyId, thirdPartyId) {
    try {
      return await axios.get(BASE_URL + "account/thirdPartyCurrencyAccounts", {
        headers: authHeader(),
        params: { currencyId: currencyId, thirdPartyId: thirdPartyId },
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  async getThirdPartyAccountsById(thirdPartyId) {
    try {
      return await axios.get(BASE_URL + "account/thirdPartyAccounts", {
        headers: authHeader(),
        params: {thirdPartyId : thirdPartyId},
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

}

export default new accountService();
