<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="ma-4 font-weight-medium">Crypto Deal</h3>
    <v-form
      class="mt-11"
      ref="third_crypto_form"
      :disabled="
        !(currentStatus === 'NOT START') &&
        !(currentStatus === 'ACCOUNTS_UPDATED')
      "
    >
      <v-row class="ma-5 mb-3">
        <v-col cols="12" md="3" sm="12" xs="12">
          <v-tab
            :disabled="!(currentStatus === 'NOT START')"
            @click="thirdPartyListModal = true"
            prepend-icon="mdi-magnify"
            color="button_color"
            >select
          </v-tab>
          <v-text-field
            :disabled="!(currentStatus === 'NOT START')"
            label="Third Party Name"
            v-model="thirdPartyFullName"
            @keyup.backspace="clearThirdParty"
            @keydown="handleKeyDown"
            :rules="requiredRule"
          ></v-text-field>
          <v-text-field
            :disabled="!(currentStatus === 'NOT START')"
            label="Reference No."
            v-model="referenceNo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12" xs="12" class="mt-25px">
          <div class="d-flex justify-start">
            <v-checkbox
              color="button_color"
              label="Use third-party funds"
              v-model="fromCustomerFunds"
              :disabled="!(currentStatus === 'NOT START')"
            >
            </v-checkbox>
          </div>
          <v-text-field
            v-maska:[exchangeRateOption]
            :label="$t('exchange rate')"
            v-model="exchangeRateFormat"
            readonly
            variant="outlined"
            :disabled="
              !(currentStatus === 'NOT START') &&
              !(currentStatus === 'ACCOUNTS_UPDATED')
            "
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="12" xs="12" class="mt-25px">
          <v-autocomplete
            :disabled="!(currentStatus === 'NOT START')"
            label="Receive Currency"
            v-model="selectedFirstCurrency"
            :items="currencies"
            item-title="name"
            item-value="name"
            filled
            return-object
            :rules="requiredRule"
          ></v-autocomplete>
          <v-autocomplete
            :disabled="!(currentStatus === 'NOT START')"
            label="Send Currency"
            v-model="selectedSecondCurrency"
            :items="currencies"
            item-title="name"
            item-value="name"
            filled
            return-object
            :rules="requiredRule"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3" sm="12" xs="12" class="mt-25px">
          <v-text-field
            v-maska:[moneyOption]
            label="We bought (Amount)"
            v-model="WeBought"
            :rules="requiredRule"
          ></v-text-field>

          <v-text-field
            v-maska:[moneyOption2]
            label="We sold (Amount)"
            v-model="WeSold"
            :rules="requiredRule"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-4" justify="end">
        <v-btn
          class="mr-4"
          color="button_color"
          @click="createOrReconcileCryptoDeal"
          :variant="currentStatus === 'ACCOUNTS_UPDATED' ? 'tonal' : 'flat'"
          :disabled="
            !(currentStatus === 'NOT START') &&
            !(currentStatus === 'ACCOUNTS_UPDATED')
          "
        >
          {{ currentStatus === "ACCOUNTS_UPDATED" ? "Reconcile" : "register" }}
        </v-btn>
        <v-btn
            v-if="currentStatus === 'ACCOUNTS_UPDATED'"
            class="mr-1"
            color="red"
            @click="deleteCryptoDeal"
        >
          Delete
        </v-btn>
      </v-row>
    </v-form>

    <v-card id="receive-side" class="custom-border ma-4" variant="outlined">
      <v-form
        ref="receive_side_form"
        :disabled="!(currentStatus === 'INITIATED')"
      >
        <h3 class="ma-4 font-weight-medium">Receive Side</h3>
        <v-row justify="end" class="ma-1">
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
              :disabled="!(currentStatus === 'INITIATED') || fromCustomerFunds"
              @click="ourAccountReceiveSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              label="Our Account"
              readonly
              v-model="ourAccountFullNameReceiveSide"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
              :disabled="!(currentStatus === 'INITIATED')"
              @click="thirdPartyAccountReceiveSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              label="Third Party Account"
              readonly
              v-model="thirdPartyAccountFullNameReceiveSide"
            ></v-text-field>
          </v-col>

          <v-col cols="3" md="3" sm="12" xs="12" class="mt-25px">
            <v-text-field
              readonly
              label="We Bought (Amount)"
              v-model="WeBought"
            >
            </v-text-field>
          </v-col>
          <v-col class="d-flex justify-end" cols="3" align-self="end">
            <v-btn
              color="button_color"
              @click="addReceiveSideBtn"
              :disabled="!(currentStatus === 'INITIATED')"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-card id="send-side" class="custom-border ma-4" variant="outlined">
      <v-form
        ref="send_side_form"
        :disabled="!(currentStatus === 'RECEIVE_CONFIRMED')"
      >
        <h3 class="ma-4 font-weight-medium">Send Side</h3>
        <v-row justify="end" class="ma-1">
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
              :disabled="!(currentStatus === 'RECEIVE_CONFIRMED')"
              @click="ourAccountSendSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              label="Our Account"
              readonly
              v-model="ourAccountFullNameSendSide"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
              :disabled="!(currentStatus === 'RECEIVE_CONFIRMED')"
              @click="thirdPartyAccountSendSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              label="Third Party Account"
              readonly
              v-model="thirdPartyAccountFullNameSendSide"
            ></v-text-field>
          </v-col>

          <v-col cols="3" md="3" sm="12" xs="12" class="mt-25px">
            <v-text-field readonly label="We Sold (Amount)" v-model="WeSold">
            </v-text-field>
          </v-col>
          <v-col align-self="end" class="d-flex justify-end">
            <v-btn
              class="mr-1"
              color="button_color"
              @click="addSendSideBtn"
              :disabled="!(currentStatus === 'RECEIVE_CONFIRMED')"
            >
              Confirm
            </v-btn>

          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>
  <v-dialog v-model="thirdPartyListModal" width="auto">
    <third_party_list_modal thirdPartyType="ALL"></third_party_list_modal>
  </v-dialog>
  <v-dialog v-model="ourAccountReceiveSideListModal" width="auto">
    <list_of_accounts_modal_v01
      :currency-id="selectedFirstCurrencyId"
    ></list_of_accounts_modal_v01>
  </v-dialog>
  <v-dialog v-model="thirdPartyAccountReceiveSideListModal" width="auto">
    <list_of_accounts_modal_v01
      :thirdPartyId="this.thirdParty.id"
      :currency-id="selectedFirstCurrencyId"
    >
    </list_of_accounts_modal_v01>
  </v-dialog>
  <v-dialog v-model="ourAccountSendSideListModal" width="auto">
    <list_of_accounts_modal_v01
      :currency-id="selectedSecondCurrencyId"
    ></list_of_accounts_modal_v01>
  </v-dialog>
  <v-dialog v-model="thirdPartyAccountSendSideListModal" width="auto">
    <list_of_accounts_modal_v01
      :thirdPartyId="this.thirdParty.id"
      :currency-id="selectedSecondCurrencyId"
    >
    </list_of_accounts_modal_v01>
  </v-dialog>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import third_party_list_modal from "../../Modals/Third_Party_List_Modal_V01.vue";
import emitter from "@/plugins/mitt";
import { ExchangeRateOption, MoneyOption } from "@/utils/maska_options";
import currencyService from "@/services/currency.service";
import {
  extractNumber,
  formatCurrencyInfo,
  formatNumberInternal,
  handleKeyDown,
  unifiedResponseHandler,
  setPage,
} from "@/utils/helpers";
import cryptoService from "@/services/crypto.service";
import { mapGetters } from "vuex";
import list_of_accounts_modal_v01 from "@/components/Modals/List_Of_Accounts_Modal_V01.vue";
import { requiredRule } from "@/utils/rules";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";

export default {
  name: "proforma_invoice_v01",
  components: {
    Success_Modal,
    Error_Modal,
    list_of_accounts_modal_v01,
    Broken_Page_V01,
    third_party_list_modal,
  },
  data() {
    return {
      message: null,
      broken_page: false,
      success: false,
      error: false,
      thirdPartyListModal: false,
      thirdParty: null,
      WeBought: null,
      currencies: [],
      selectedFirstCurrency: null,
      selectedSecondCurrency: null,
      referenceNo: null,
      exchangeRateOption: ExchangeRateOption,
      requiredRule: requiredRule,
      exchangeRate: null,
      WeSold: null,
      currentStatus: "NOT START",
      ourAccount: null,
      ourAccountReceiveSideListModal: false,
      thirdPartyAccountReceiveSideListModal: false,
      ourAccountSendSideListModal: false,
      thirdPartyAccountSendSideListModal: false,
      thirdPartyAccount: null,
      thirdPartyAccountSendSide: null,
      ourAccountSendSide: null,
      exchangeRateEdit: null,
      cryptoDealId: null,
      fromCustomerFunds: false,
    };
  },
  methods: {
    handleKeyDown,
    async getCurrencies() {
      let response = unifiedResponseHandler(
        await currencyService.getCurrencies(),
      );
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    clearThirdParty() {
      this.thirdParty = null;
    },

    createOrReconcileCryptoDeal() {
      if (this.currentStatus === "NOT START") this.createCryptoDeal();
      else this.reconcileCryptoDeal();
    },

    async createCryptoDeal() {
      let { valid } = await this.$refs.third_crypto_form.validate();
      if (valid) {
        let form = {
          fromCustomerFunds: this.fromCustomerFunds,
          thirdPartyId: this.thirdPartyId,
          referenceNo: this.referenceNo,
          receiveAmount: extractNumber(this.WeBought),
          sendAmount: extractNumber(this.WeSold),
          receiveCurrencyId: this.selectedFirstCurrencyId,
          sendCurrencyId: this.selectedSecondCurrencyId,
          exchangeRate: extractNumber(this.exchangeRateFormat),
        };
        let response = unifiedResponseHandler(
          await cryptoService.createCryptoDeal(form),
        );
        if (response.success) {
          this.success = true;
          this.message = response.message;
          this.currentStatus = "INITIATED";
          this.cryptoDealId = response.data.id;
        } else {
          this.error = true;
          this.message = response.message;
        }
      }
    },
    async reconcileCryptoDeal() {
      let { valid } = await this.$refs.third_crypto_form.validate();
      if (valid) {
        let form = {
          id: this.cryptoDealId,
          receiveAmount: extractNumber(this.WeBought),
          sendAmount: extractNumber(this.WeSold),
          exchangeRate: extractNumber(this.exchangeRateFormat),
        };
        let response = unifiedResponseHandler(
          await cryptoService.reconcileCryptoDeal(form),
        );
        if (response.success) {
          this.success = true;
          this.message = response.message;
          setTimeout(() => setPage("crypto report"), 1000);
        } else {
          this.error = true;
          this.message = response.message;
        }
      }
    },

    async addReceiveSideBtn() {
      if (
        this.selectedFirstCurrency.id === this.thirdPartyAccount.currency.id
      ) {
        let { valid } = await this.$refs.receive_side_form.validate();
        if (valid) {
          let response = unifiedResponseHandler(
            await cryptoService.setCryptoDealReceiveSide(
              this.cryptoDealId,
              this.ourAccountIdReceiveSide,
              this.thirdPartyAccountIdReceiveSide,
            ),
          );
          this.message = response.message;
          if (response.success) {
            this.currentStatus = "RECEIVE_CONFIRMED";
            this.success = true;
          } else {
            this.error = true;
          }
        }
      } else {
        this.error = true;
        this.message =
          "The currency selected in Receive Currency is different from the currency of the account";
      }
    },
    async deleteCryptoDeal() {
      if (this.currentStatus === "ACCOUNTS_UPDATED") {
        let response = unifiedResponseHandler(
          await cryptoService.deleteCryptoDeal(this.cryptoDealId),
        );
        this.message = response.message;
        if (response.success) {
          this.currentStatus = "DELETED";
          this.success = true;
        } else {
          this.error = true;
        }
      }
    },

    async addSendSideBtn() {
      if (
        this.selectedSecondCurrency.id ===
        this.thirdPartyAccountSendSide.currency.id
      ) {
        let { valid } = await this.$refs.send_side_form.validate();
        if (valid) {
          let response = unifiedResponseHandler(
            await cryptoService.setCryptoDealSendSide(
              this.cryptoDealId,
              this.ourAccountIdSendSide,
              this.thirdPartyAccountIdSendSide,
            ),
          );
          this.message = response.message;
          if (response.success) {
            this.currentStatus = "COMPLETED";
            this.success = true;
            await setPage("crypto report");
          } else {
            this.error = true;
          }
        }
      } else {
        this.error = true;
        this.message =
          "The currency selected in Send Currency is different from the currency of the account";
      }
    },
  },
  computed: {
    ...mapGetters(["chosen_crypto_deal", "is_editing_crypto_deal", "page"]),
    ourAccountFullNameReceiveSide() {
      if (this.ourAccount) {
        return this.ourAccount.cofferName;
      } else {
        return null;
      }
    },
    ourAccountIdReceiveSide() {
      if (this.ourAccount) {
        return this.ourAccount.id;
      } else {
        return null;
      }
    },
    ourAccountFullNameSendSide() {
      if (this.ourAccountSendSide) {
        return this.ourAccountSendSide.cofferName;
      } else {
        return null;
      }
    },
    ourAccountIdSendSide() {
      if (this.ourAccountSendSide) {
        return this.ourAccountSendSide.id;
      } else {
        return null;
      }
    },
    thirdPartyAccountFullNameSendSide() {
      if (this.thirdPartyAccountSendSide) {
        return this.thirdPartyAccountSendSide.cofferName;
      } else {
        return null;
      }
    },
    thirdPartyAccountIdSendSide() {
      if (this.thirdPartyAccountSendSide) {
        return this.thirdPartyAccountSendSide.id;
      } else {
        return null;
      }
    },
    thirdPartyAccountFullNameReceiveSide() {
      if (this.thirdPartyAccount) {
        return this.thirdPartyAccount.cofferName;
      } else {
        return null;
      }
    },
    thirdPartyAccountIdReceiveSide() {
      if (this.thirdPartyAccount) {
        return this.thirdPartyAccount.id;
      } else {
        return null;
      }
    },

    thirdPartyFullName() {
      if (this.thirdParty) {
        return this.thirdParty.fullName;
      } else {
        return null;
      }
    },

    thirdPartyId() {
      if (this.thirdParty) {
        return this.thirdParty.id;
      } else {
        return null;
      }
    },
    moneyOption() {
      if (this.selectedFirstCurrency) {
        return MoneyOption(this.selectedFirstCurrency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    moneyOption2() {
      if (this.selectedSecondCurrency) {
        return MoneyOption(this.selectedSecondCurrency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    selectedSecondCurrencyId() {
      return this.selectedSecondCurrency?.id || null;
    },
    selectedFirstCurrencyId() {
      return this.selectedFirstCurrency?.id || null;
    },
    exchangeRateFormat() {
      if (this.WeBought && this.WeSold) {
        let extractedWeBought = parseFloat(extractNumber(this.WeBought));
        let extractedWeSold = parseFloat(extractNumber(this.WeSold));
        if (extractedWeBought >= extractedWeSold) {
          return formatNumberInternal(extractedWeBought / extractedWeSold);
        } else {
          return formatNumberInternal(extractedWeSold / extractedWeBought);
        }
      }
      return null;
    },
  },
  async mounted() {
    emitter.on("new_third_party", (value) => {
      if (this.currentStatus === "NOT START") {
        this.thirdParty = value;
        this.thirdPartyListModal = false;
      }
    });

    emitter.on("chosenAccount", (value) => {
      if (this.thirdPartyAccountReceiveSideListModal) {
        this.thirdPartyAccount = value;
        this.thirdPartyAccountReceiveSideListModal = false;
      } else if (this.thirdPartyAccountSendSideListModal) {
        this.thirdPartyAccountSendSide = value;
        this.thirdPartyAccountSendSideListModal = false;
      } else if (this.ourAccountReceiveSideListModal) {
        this.ourAccount = value;
        this.ourAccountReceiveSideListModal = false;
      } else if (this.ourAccountSendSideListModal) {
        this.ourAccountSendSide = value;
        this.ourAccountSendSideListModal = false;
      }
    });

    if (this.is_editing_crypto_deal) {
      this.cryptoDealId = this.chosen_crypto_deal.id;
      this.receiveAmount = this.chosen_crypto_deal.receiveAmount;
      this.currentStatus = this.chosen_crypto_deal.status;
      this.thirdParty = this.chosen_crypto_deal.thirdParty;
      this.selectedSecondCurrency = this.chosen_crypto_deal.sendCurrency;
      this.selectedFirstCurrency = this.chosen_crypto_deal.receiveCurrency;
      this.exchangeRateEdit = this.chosen_crypto_deal.exchangeRate;
      this.WeBought = this.chosen_crypto_deal.receiveAmount;
      this.WeSold = this.chosen_crypto_deal.sendAmount;
      this.referenceNo = this.chosen_crypto_deal.referenceNo;
      this.thirdPartyAccount = this.chosen_crypto_deal.counterPartyPayerAccount;
      this.ourAccount = this.chosen_crypto_deal.ellingtonReceiveAccount;
      this.ourAccountSendSide = this.chosen_crypto_deal.ellingtonSendAccount;
      this.thirdPartyAccountSendSide =
        this.chosen_crypto_deal.counterPartyPayeeAccount;
      this.fromCustomerFunds = this.chosen_crypto_deal.fromCustomerFunds;
    }
    await this.getCurrencies();
  },
  beforeUnmount() {
    this.$store.dispatch("setIsEditingCryptoDeal", false);
    this.$store.dispatch("setChosenCryptoDeal", null);
  },
};
</script>

<style scoped>
.custom-border {
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}
</style>
