// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

import { createVuetify } from "vuetify";
import safeBoxWithdraw from "@/assets/img/safe-box-operation/safeBoxWithdraw.vue";
import safeBoxDeposit from "@/assets/img/safe-box-operation/safeBoxDeposit.vue";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VCalendar } from "vuetify/labs/VCalendar";

export const light_mode = {
  colors: {
    primaryColor1:"#FF6E40",
    primaryColor2:"#FBE9E7",
    button_color: "#FF6E40",
    side_color: "#F5F5F5",
    footer_color: "#F5F5F5",
    surface: "#FFFFFF",
    nav_color: "#FFFFFF",
  },
};



const dark_mode = {
  colors: {
    button_color: "#FF6E40",
    side_color: "#757575",
    footer_color: "#757575",
    surface: "#BDBDBD",
    nav_color: "#000000",
  },
};

export default createVuetify({
  components: {
    VCalendar,
    ...components,
  },
  directives,
  theme: {
    defaultTheme: "light_mode",
    themes: {
      light_mode,
      dark_mode,
    },
  },
  icons: {
    aliases: {
      safeBoxDeposit: safeBoxDeposit,
      safeBoxWithdraw: safeBoxWithdraw,
    },
  },
});
