<template>
  <div>
    <v-container fluid>
      <Vue3MultiStepper
          v-if="currentStatus !== 'CANCELED'"
          v-model:step="step"
          :tabs="tabs"
          :primaryColor1="primaryColor1"
          :primaryColor2="primaryColor2"
          showNavigation="false"
          backText=""
          nextText=""
          doneText="Finish"
          :loading="loading"

      >
      </Vue3MultiStepper>
      <v-form
          ref="cash_receive_request_form"
          style="margin-top: -50px"
      >
        <h3 class="font-weight-medium">{{ requestTypeFormat(requestType) }}</h3>

        <v-row id="money-transfer-form" class="mt-4">
          <v-col cols="12" md="3" sm="3" xs="12">
            <v-tab
                :disabled="!(currentStatus === 'NOT INITIATED' || currentStatus === 'INITIATED')"
                @click="accountListModal = true; listOfAccountsModalThirdPartyTypeProp='CUSTOMER'"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>

            <v-text-field
                label="Customer Account"
                v-model="customerAccountCofferName"
                @keyup.backspace="clearCustomer"
                @keydown="handleKeyDown"
                :disabled="!(currentStatus === 'NOT INITIATED' ||currentStatus === 'INITIATED')"
                :rules="requiredRule"
            ></v-text-field>
            <v-text-field
                class="mt-6"
                v-maska:[moneyOption]
                label="Amount"
                v-model="amount"
                :disabled="
                !(
                  currentStatus === 'NOT INITIATED' ||
                  currentStatus === 'INITIATED' ||
                  currentStatus === 'ACCOUNTS_UPDATED'
                )
              "
            ></v-text-field>
            <v-tab
                :disabled="!(currentStatus === 'NOT INITIATED' ||currentStatus === 'INITIATED')"
                @click="accountListModal = true; listOfAccountsModalThirdPartyTypeProp='TRUSTEE'"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field
                :disabled="!(currentStatus === 'NOT INITIATED' ||currentStatus === 'INITIATED')"
                label="Trustee Account"
                v-model="tAccountCofferName"
                @keyup.backspace="clearTAccount"
                @keydown="handleKeyDown"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3" xs="12">
            <v-text-field
                readonly
                label="Customer Balance"
                v-model="customerAccountBalance"
                variant="outlined"
                :disabled="!customerAccount"
                class="mt-25px"
            >
            </v-text-field>
            <v-tab
                :disabled="
                !(
                  currentStatus === 'NOT INITIATED' ||
                  currentStatus === 'INITIATED'
                )
              "
                @click="accountListModal = true; listOfAccountsModalThirdPartyTypeProp='SECONDARY_THIRDPARTY'"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>

            <v-text-field
                label="Payer/Payee Account"
                v-model="secondaryThirdPartyAccountCofferName"
                @keyup.backspace="clearSecondaryThirdPartyAccount"
                @keydown="handleKeyDown"
                :disabled="
                !(
                  currentStatus === 'NOT INITIATED' ||
                  currentStatus === 'INITIATED'
                )
              "
            ></v-text-field>
            <v-text-field
                class="mt-25px"
                readonly
                :disabled="!tAccount"
                label="Trustee Balance"
                v-model="tAccountActualAmountCurrencySymbol"
                variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3" xs="12">
            <v-text-field
                class="mt-25px"
                label="Reference No."
                v-model="referenceNo"
                :disabled="
                !(
                  currentStatus === 'NOT INITIATED' ||
                  currentStatus === 'INITIATED' ||
                  currentStatus === 'ACCOUNTS_UPDATED'
                )
              "
            ></v-text-field>
            <v-text-field
                class="mt-25px"
                v-maska:[moneyOption]
                :disabled="disableCommissionField"
                label="Customer Com Amt"
                v-model="ellingtonCommissionAmount"
            ></v-text-field>
            <v-text-field
                class="mt-25px"
                :disabled="disableCommissionField"
                v-maska:[moneyOption]
                label="Trustee Com Amt"
                v-model="trusteeCommissionAmount"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3" xs="12">
            <v-text-field style="visibility: hidden" class="mt-25px">
            </v-text-field>
            <v-text-field
                class="mt-25px"
                v-maska:[percentageOption]
                :disabled="disableCommissionField"
                label="Customer Com %"
                v-model="ellingtonCommissionPercent"
            ></v-text-field>
            <v-text-field
                class="mt-25px"
                v-maska:[percentageOption]
                :disabled="disableCommissionField"
                label="Trustee Com %"
                v-model="trusteeCommissionPercent"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card
            v-if="customerAccount"
            class="my-6 mr-3 ml-3 custom-border"
            variant="outlined"
            title="Customer Account Info"
        >
          <v-table class="m-2">
            <thead>
            <tr>
              <th class="text-left">Actual Balance</th>
              <th class="text-left">In Transit Credit</th>
              <th class="text-left">In Transit Debit</th>
              <th class="text-left">Blocked Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ formatNumber(customerAccount.actualAmount) }}</td>
              <td>
                {{ formatNumber(customerAccount.inTransitCreditAmount) }}
              </td>
              <td>
                {{ formatNumber(customerAccount.inTransitDebitAmount) }}
              </td>
              <td>{{ formatNumber(customerAccount.blockedAmount) }}</td>
            </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-form>


      <v-text-field
          class="mt-4"
          :disabled="!(currentStatus === 'NOT INITIATED' ||currentStatus === 'INITIATED' || currentStatus === 'ACCOUNTS_UPDATED')"
          v-model="description"
          label="Note"
          @input="checkDescriptionLength"
      >
      </v-text-field>

      <v-form>
        <v-row class="mt-4">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4" sm="4" xs="12">
                <v-file-input
                    v-model="selectedFiles"
                    label="Select File"
                    multiple
                    :accept="acceptedFileTypes"
                    :rules="multipleFileRequiredRule"
                >
                </v-file-input>
              </v-col>
              <v-col cols="auto">
                <v-btn color="button_color" class="mt-4">Upload</v-btn>
              </v-col>
            </v-row>
            <v-list>
              <v-list-item v-for="(file, index) in selectedFiles" :key="index">
                <v-icon @click="remove(file)">mdi-delete</v-icon>
                {{ file.name }}
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-toolbar-title class="ml-4"> all files:</v-toolbar-title>
            <v-list class="mt-4">
              <v-list-item v-for="(file, index) in files" :key="index">
                <v-icon>mdi-download</v-icon>
                {{ file.name }}
              </v-list-item>
            </v-list>
          </v-container>
        </v-row>
      </v-form>

      <v-row
          class="ma-4"
          style="
            width: 95%;
            display: grid;
            grid-template: auto auto / auto auto;
            grid-gap: 10px;
            justify-content: end;
            align-items: end;
          "
      >
        <v-btn
            elevation="4"
            :disabled="!(currentStatus === 'NOT INITIATED' ||currentStatus === 'INITIATED' || currentStatus === 'ACCOUNTS_UPDATED')"
            width="150"
            height="70"
            color="button_color"
            @click="handleSaveOrRegisterOrReconciliationButton()"
        >
          {{ buttonText }}
        </v-btn>
        <v-btn
            elevation="4"
            width="150"
            height="70"
            color="button_color"
            @click="handleSaveAndNextButtonClick()"
            :disabled="currentStatus==='ACCOUNTS_UPDATED'"
        >
          Save And Next
        </v-btn>
        <v-btn
            elevation="4"
            color="button_color"
            width="150"
            height="70"
            @click="this.exit()"
        >
          Exit
        </v-btn>
        <v-btn
            :disabled="currentStatus === 'NOT INITIATED'"
            elevation="4"
            width="150"
            height="70"
            @click="this.currentStatus === 'ACCOUNTS_UPDATED' ? requestForDeletion = true :
                    this.currentStatus !=='CANCELED' ? requestForCancelRequest = true : null"
            color="red"
            variant="tonal"
        >
          {{ cancelOrDeleteRequest }}
        </v-btn>
      </v-row>

      <Success_Modal
          :message="message"
          :success="success"
          @closeSuccessModal="success = false"
      ></Success_Modal>
      <Error_Modal
          :message="message"
          :error="error"
          @closeErrorModal="error = false"
      ></Error_Modal>

      <v-dialog v-model="requestForCancelRequest">
        <accept-or-decline-with-info-modal
            :screenWidth="screenWidth"
            :color="primaryColor1"
            @decline="requestForCancelRequest = false"
            @accept="cancelRequest"
            title="Are you sure you want to cancel this request?"
            accept-text="Cancel Request"
            decline-text="Close"
        ></accept-or-decline-with-info-modal>
      </v-dialog>
      <v-dialog v-model="requestForApproveModal">
        <accept-or-decline-with-info-modal
            :screenWidth="screenWidth"
            :color="primaryColor1"
            @decline="cancelRequest"
            @accept="requestForApprove"
            title="Not enough balance"
            accept-text="Request For Approval"
            decline-text="Cancel Request"
        ></accept-or-decline-with-info-modal>
      </v-dialog>
      <v-dialog v-model="requestForDeletion">
        <accept-or-decline-with-info-modal
            :screenWidth="screenWidth"
            :color="primaryColor1"
            @decline="requestForDeletion = false"
            @accept="deleteRequest"
            title="Are you sure you want to delete this request?"
            accept-text="Delete Request"
            decline-text="Close"
        ></accept-or-decline-with-info-modal>
      </v-dialog>


      <v-dialog v-model="accountListModal" width="auto">
        <list_of_accounts_modal_v01
            :third-party-type="listOfAccountsModalThirdPartyTypeProp"
        >
        </list_of_accounts_modal_v01>
      </v-dialog>

    </v-container>
  </div>
</template>
<script>
import CurrencyService from "@/services/currency.service";
import {
  extractNumber,
  formatCurrencyInfo,
  formatNumber,
  getIndex,
  handleKeyDown,
  unifiedResponseHandler,
  setPage,
} from "@/utils/helpers";
import {multipleFileRequiredRule, numericRequiredRule, numericRule, requiredRule,} from "@/utils/rules";
import list_of_accounts_modal_v01 from "../../Modals/List_Of_Accounts_Modal_V01.vue";
import emitter from "@/plugins/mitt";
import money_transfer_requestService from "@/services/money_transfer_request.service";
import {ACCEPTED_FILE_TYPES, MONEY_TRANSFER_TABS} from "@/config/constants";
import {mapGetters} from "vuex";
import {Vue3MultiStepper} from "vue3-multi-stepper";
import {MoneyOption, PercentageOption} from "@/utils/maska_options";
import "jspdf-autotable";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import {useTheme} from "vuetify";
import AcceptOrDeclineWithInfoModal from "@/components/Utils/Modals/accept-or-decline-with-info-modal.vue";

export default {
  name: "money_transfer_v01",
  components: {
    AcceptOrDeclineWithInfoModal,
    list_of_accounts_modal_v01,
    Error_Modal,
    Success_Modal,
    Vue3MultiStepper,
  },
  props: ["requestType"],
  data() {
    return {
      screenWidth: window.innerWidth,
      percentageOption: PercentageOption,
      loading: false,
      tabs: MONEY_TRANSFER_TABS,
      currencies: [],
      error: false,
      success: false,
      message: null,
      step: 1,
      currentStatus: "NOT INITIATED",
      requiredRule: requiredRule,
      numericRule: numericRule,
      numericRequiredRule: numericRequiredRule,
      referenceNo: null,
      requestId: null,
      customerAccount: null,
      amount: null,
      secondaryThirdPartyAccount: null,
      tAccount: null,
      trusteeCommissionAmount: null,
      trusteeCommissionPercent: null,
      ellingtonCommissionAmount: null,
      ellingtonCommissionPercent: null,
      accountListModal: false,
      selectedFiles: [],
      files: [],
      requestForApproveModal: false,
      listOfAccountsModalThirdPartyTypeProp: null,
      requestForCancelRequest: false,
      requestForDeletion: false,
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      multipleFileRequiredRule: multipleFileRequiredRule,
      description: null,
      proceedAllowed: true,
    };
  },
  methods: {
    async handleSaveAndNextButtonClick() {
      switch (this.currentStatus) {
        case "NOT INITIATED":
        case "INITIATED":
          await this.addRequest(false, false);
          if (this.proceedAllowed) await this.generateTransaction();
          else this.proceedAllowed = true;
          break;
        case "APPROVED":
          await this.generateTransaction();
          break;
        case "TRANSACTION_REQUESTED":
          await this.trusteeConfirmed();
          break;
        case "TRUSTEE_CONFIRMED":
          await this.customerConfirmed();
          this.exit();
          break;
        case "requestForCancelRequest":
          if (
              this.currentStatus !== "COMPLETED" &&
              this.currentStatus !== "CANCELED"
          ) {
            this.requestForCancelRequest = true;
          }
          break;
        default:
          this.exit();
      }
    },
    async handleSaveOrRegisterOrReconciliationButton() {
      if (this.buttonText === 'Reconciliation')
        await this.doReconciliation()
      else
        await this.addRequest(true)
    },
    requestTypeFormat(requestType) {
      let transactionTypeDisplay = "";
      if (requestType === "CASH_RECEIVE") {
        transactionTypeDisplay = "Cash Receive";
      } else if (requestType === "CASH_SEND") {
        transactionTypeDisplay = "Cash Send";
      } else if (requestType === "BANK_RECEIVE") {
        transactionTypeDisplay = "Bank Receive";
      } else if (requestType === "BANK_SEND") {
        transactionTypeDisplay = "Bank Send";
      }
      return transactionTypeDisplay;
    },
    checkDescriptionLength() {
      if (this.description.length > 255) {
        this.description = this.description.slice(0, 255);
        this.error = true;
        this.message = "Description is too long (max 255 characters). :)";
      }
    },
    formatNumber,
    next() {
      this.step = this.step + 1;
    },
    clearTAccount() {
      this.tAccount = null;
    },
    clearCustomer() {
      this.customerAccount = null;
    },
    clearSecondaryThirdPartyAccount() {
      this.secondaryThirdPartyAccount = null;
    },
    remove(file) {
      this.selectedFiles = this.selectedFiles.filter((item) => item !== file);
    },
    exit(withoutTimeOut = false) {
      if (withoutTimeOut)
        setPage("transfer report");
      setTimeout(() => {
        setPage("transfer report");
      }, 900);
    },
    async getCurrencies() {
      let response = unifiedResponseHandler(await CurrencyService.getCurrencies());
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },

    async addRequest(exit = false, successDisplay = true) {
      let {valid} = await this.$refs.cash_receive_request_form.validate();
      if (valid) {
        let form = {
          currencyId: this.selectedCurrency ? this.selectedCurrency.id : null,
          customerId: this.customerAccountThirdPartyId,
          referenceNo: this.referenceNo,
          secondaryThirdPartyAccountId: this.secondaryThirdPartyAccountId,
          amount: extractNumber(this.amount),
          requestType: this.requestType,
          description: this.description,
          trusteeAccountId: this.tAccountId,
          trusteeCommissionAmount: extractNumber(this.trusteeCommissionAmount),
          trusteeCommissionPercent: extractNumber(this.trusteeCommissionPercent),
          ellingtonCommissionPercent: extractNumber(this.ellingtonCommissionPercent),
          ellingtonCommissionAmount: extractNumber(this.ellingtonCommissionAmount),
          id: this.requestId,
        };
        let response = unifiedResponseHandler(
            await money_transfer_requestService.createMoneyTransferRequest(form)
        );
        this.message = response.message;
        if (response.success) {
          if (exit) {
            this.exit();
          }
          if (successDisplay)
            this.success = true;
          this.requestId = response.data.id;
          this.currentStatus = "INITIATED";
          this.step = 2;
          this.$store.dispatch("setChosenRequest", response.data);
        } else {
          this.error = true;
          this.proceedAllowed = false;
        }
      } else {
        console.log("not valid");
      }
    },
    async generateTransaction(exit = false) {
      let response = unifiedResponseHandler(
          await money_transfer_requestService.generateTransaction(this.requestId)
      );
      this.message = response.message;
      if (response.success) {
        if (exit) {
          this.exit();
        }
        this.success = true;
        this.currentStatus = "TRANSACTION_REQUESTED";
        this.step = 5;
      } else if (
          response.message === "Customer balance is not enough" ||
          response.message === "Trustee balance is not enough"
      ) {
        this.requestForApproveModal = true;
      } else {
        this.error = true;
      }
    },
    async requestForApprove() {
      let response = unifiedResponseHandler(
          await money_transfer_requestService.changeRequestStatusToWaitingForApprove(
              this.requestId
          )
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.requestForApproveModal = false;
        this.currentStatus = "WAITING_FOR_APPROVAL";
        this.step = 3;
      } else {
        this.error = true;
      }
    },
    async cancelRequest() {
      let response = unifiedResponseHandler(
          await money_transfer_requestService.changeRequestStatusToCanceled(
              this.requestId
          )
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.requestForApproveModal = false;
        this.currentStatus = "CANCELED";
        setTimeout(() => {
          setPage("transfer report");
        }, 1000);
      } else {
        this.error = true;
        this.message = "You cannot cancel the request!";
      }
    },
    async deleteRequest() {
      this.requestForDeletion = false;
      let response = unifiedResponseHandler(
          await money_transfer_requestService.deleteById(this.requestId)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        setTimeout(() => {
          setPage("transfer report");
        }, 1000);
      } else {
        this.error = true;
      }
    },
    async doReconciliation() {
      const {valid} = await this.$refs.cash_receive_request_form.validate();
      if (!valid)
        return;
      let form = {
        eventId: this.requestId,
        amount: extractNumber(this.amount),
        trusteeCommissionAmount: extractNumber(this.trusteeCommissionAmount),
        trusteeCommissionPercent: extractNumber(this.trusteeCommissionPercent),
        ellingtonCommissionPercent: extractNumber(this.ellingtonCommissionPercent),
        ellingtonCommissionAmount: extractNumber(this.ellingtonCommissionAmount),
        description: this.description,
        referenceNo: this.referenceNo
      }
      let response = unifiedResponseHandler(
          await money_transfer_requestService.doReconciliation(form)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.exit();
      } else {
        this.error = true;
      }
    },

    handleKeyDown(event) {
      handleKeyDown(event);
    },

    async trusteeConfirmed() {
      let response = unifiedResponseHandler(
          await money_transfer_requestService.trusteeConfirmed(this.requestId)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.currentStatus = "TRUSTEE_CONFIRMED";
        this.step = 6;
      } else {
        this.error = true;
      }
    },

    async customerConfirmed() {
      let response = unifiedResponseHandler(
          await money_transfer_requestService.customerConfirmed(this.requestId)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.currentStatus = "COMPLETED";
        this.step = 7;
      } else {
        this.error = true;
      }
    },

  },
  async mounted() {
    emitter.on("chosenAccount", (value) => {
      this.accountListModal = false;
      if (value.thirdParty.thirdPartyType === "CUSTOMER") {
        this.customerAccount = value;
      }
      if (value.thirdParty.thirdPartyType === "TRUSTEE") {
        this.tAccount = value;
      }
      if (value.thirdParty.thirdPartyType === "SECONDARY_THIRDPARTY") {
        this.secondaryThirdPartyAccount = value;
      }
    });

    emitter.on("close_t_c_account", () => {
      this.accountListModal = false;
    });

    if (this.is_editing_request) {
      this.description = this.chosen_request.description !== null ? this.chosen_request.description.trimStart() : null;
      this.requestId = this.chosen_request.id;
      this.customerAccount = this.chosen_request.customerAccount;
      this.secondaryThirdPartyAccount = this.chosen_request.secondaryThirdPartyAccount;
      this.amount = this.chosen_request.amount;
      this.referenceNo = this.chosen_request.referenceNo;
      this.tAccount = this.chosen_request.trusteeAccount;
      this.trusteeCommissionAmount = this.chosen_request.trusteeCommissionAmount;
      this.trusteeCommissionPercent = this.chosen_request.trusteeCommissionPercent;
      this.ellingtonCommissionPercent = this.chosen_request.ellingtonCommissionPercent;
      this.ellingtonCommissionAmount = this.chosen_request.ellingtonCommissionAmount;
      this.currentStatus = this.chosen_request.status;
      this.step = getIndex(this.tabs, this.currentStatus, 0);
    }
    await this.getCurrencies();
  },

  computed: {
    cancelOrDeleteRequest() {
      if (this.currentStatus === 'ACCOUNTS_UPDATED')
        return 'Delete Request';
      else
        return 'Cancel Request';
    },
    primaryColor1() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor1
    },
    primaryColor2() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor2
    },
    ...mapGetters(["chosen_request", "is_editing_request", "page"]),
    buttonText() {
      if (this.currentStatus === 'NOT INITIATED')
        return 'Register';
      else if (this.currentStatus === 'ACCOUNTS_UPDATED')
        return 'Reconciliation'
      else
        return 'Save'
    },
    moneyOption() {
      if (this.selectedCurrency) {
        return MoneyOption(this.selectedCurrency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    customerAccountCofferName() {
      return this.customerAccount ? this.customerAccount.cofferName : null
    },
    customerAccountBalance() {
      return this.customerAccount ? formatNumber(this.customerAccount.actualAmount) + " " + this.customerAccount.currency.symbol : null
    },
    customerAccountThirdPartyId() {
      return this.customerAccount ? this.customerAccount.thirdParty.id : null
    },
    selectedCurrency() {
      return this.customerAccount ? this.customerAccount.currency :
          this.tAccount ? this.tAccount.currency :
              this.secondaryThirdPartyAccount ? this.secondaryThirdPartyAccount.currency : null
    },
    tAccountActualAmountCurrencySymbol() {
      return this.tAccount ? formatNumber(this.tAccount.actualAmount) + " " + this.tAccount.currency.symbol : null;
    },
    secondaryThirdPartyAccountCofferName() {
      return this.secondaryThirdPartyAccount ? this.secondaryThirdPartyAccount.cofferName : null
    },
    secondaryThirdPartyAccountId() {
      return this.secondaryThirdPartyAccount ? this.secondaryThirdPartyAccount.id : null
    },
    tAccountCofferName() {
      return this.tAccount ? this.tAccount.cofferName : null
    },
    tAccountId() {
      return this.tAccount ? this.tAccount.id : null
    },
    disableCommissionField() {
      const status = this.currentStatus;
      return status === "COMPLETED" || status === "TRUSTEE_CONFIRMED" || status === "TRANSACTION_REQUESTED"
          || status === "WAITING_FOR_APPROVAL" || status === "APPROVED"
    }

  },
  watch: {},
  beforeUnmount() {
    this.$store.dispatch("setIsEditingRequest", false);
    this.$store.dispatch("setChosenRequest", null);
  },
};
</script>
<style scoped>
.custom-border {
  border-width: 1px;
  border-style: solid;
  border-color: #b0b0b0;
}

.controls {
  display: none !important;
}
</style>
